import React, { ReactNode, ReactPortal } from 'react';
import { ReactComponent as LogoSmall } from '../../../assets/images/aag-logo-small.svg';
import TooltipC from '../../../components/TooltipC';

interface Props {
  text: string;
  value?: JSX.Element;
  bottomEl: JSX.Element;
  tooltip?: ReactPortal | ReactNode;
  bgColor?: string;
  border?: string;
  height?: string;
  type?: string;
  padding?: string;
  margin?: string;
  wrap?: any;
}

const ReusableWidget = (props: Props) => {
  return (
    <div
      style={{
        backgroundColor: props.bgColor,
        border: props.border,
        padding: props.padding,
        height: props.height,
        minHeight: 'calc(100% - 20px)',
        justifyContent: 'center',
      }}
      className="total-amount-widget"
    >
      <div className="top">
        <div style={{ whiteSpace: props.wrap, maxWidth: 170, margin: 'auto' }} className="label">
          {props.text}
          {props.type !== 'claim' && props.tooltip ? <TooltipC tooltip={props.tooltip} /> : null}
        </div>
      </div>
      <div className="middle">
        {!!props.value && (
          <>
            <div className="amount">
              <span
                // style={{ fontSize: props.value.length >= 12 ? '28px' : '38px' }}
                className="big-number"
              >
                {props.value}
              </span>
            </div>
            <LogoSmall style={{ width: '20px', marginRight: '10px' }} />
            <span>AAG</span>
          </>
        )}
      </div>
      <div className="bottom">{props.bottomEl}</div>
    </div>
  );
};

export default ReusableWidget;
