import React, { useEffect, useState } from 'react';
import { SUPPORTED_WALLETS } from 'src/constants/wallet';
import { useAppDispatch, useAppSelector } from 'src/state';
import { setAuthorized, setGlobalMessage, toggleAuthorizationModal } from 'src/state/app/actions';
import { AuthorizationMethods, GlobalMessage } from 'src/types';
import { uuid } from 'src/helpers';
import moment from 'moment';
import { Subject } from 'rxjs';
import { getAuthMethod } from 'src/state/app/selectors';
import { isMobile } from 'react-device-detect';
import { MetaMask } from '@web3-react/metamask';
import useActiveWeb3 from 'src/hooks/useActiveWeb3';
import { useWeb3React } from '@web3-react/core';

interface Props {
  setInfoOpen?: (state: boolean) => void;
}

export const ReinitializeMetaone = new Subject<() => any>();

const MetaOneButton: React.FC<Props> = ({ setInfoOpen }) => {
  const dispatch = useAppDispatch();
  const [connecting, setConnecting] = useState<boolean>();
  const { authorized } = useAppSelector((state) => ({
    authorized: getAuthMethod(state),
  }));

  const { connector, isActive } = useWeb3React();

  // @ts-ignore
  const isMetaone = !!window.ethereum?.isMetaOne;
  const isInjected = window.ethereum;

  const initAuth = () => {
    if (isMobile && !isMetaone) {
      window.location.replace(
        'https://metaone.page.link/?utm_campaign=dappLink&ibi=com%2Eaag%2Emetaone&isi=1627212812&apn=ventures%2Eaag%2Emetaone&link=https%3A%2F%2Fgetmeta%2Eone%3Fdapp%3Dhttps://withdrawals.aag.ventures',
      );
      return;
    }
    if (!isMetaone && !isMobile) {
      window.open('https://app.saakuru.com/', '_blank');
      return;
    }
    if (connecting) return;
    if (isInjected) {
      setConnecting(true);
      connector instanceof MetaMask &&
        void connector
          .activate()
          .then(() => {
            dispatch(setAuthorized(AuthorizationMethods.metaone));
            setConnecting(false);
            dispatch(toggleAuthorizationModal(false));
          })
          .catch((error) => {
            let msg = error?.message || 'Something went wrong';
            if (error?.message.includes('Unsupported')) {
              msg =
                'Your MetaOne is set to incorrect network. Please change it to Harmony mainnet or Ethereum mainnet before logging in';
            }
            if (error?.message.includes('window.ethereum')) {
              setInfoOpen && setInfoOpen(true);
              msg = 'No Saakuru App found.';
            }
            const message: GlobalMessage = {
              message: msg,
              uuid: uuid(),
              expiresAt: moment().add(20, 'seconds').toDate(),
              isError: true,
            };
            dispatch(setAuthorized(undefined));
            setConnecting(false);
            dispatch(toggleAuthorizationModal(false));
            dispatch(setGlobalMessage(message));
          });
      if (isActive) {
        dispatch(setAuthorized(AuthorizationMethods.metaone));
        setConnecting(false);
        dispatch(toggleAuthorizationModal(false));
      }
    } else {
      // TODO handle explanation how to download metaone
    }
  };

  useEffect(() => {
    ReinitializeMetaone.subscribe((retry: () => unknown) => {
      initAuth();
      setTimeout(() => {
        retry();
      }, 4000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authorized === 'metaone') {
      initAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      className="wallet-button"
      onClick={() => {
        initAuth();
      }}
    >
      <span style={{ fontSize: '16px' }}>{`Saakuru App`} </span>{' '}
      <div
        style={{
          width: 26,
          height: 26,
          borderRadius: '100%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={SUPPORTED_WALLETS['METAONE'].iconURL} style={{ maxWidth: '60%' }}></img>
      </div>
    </button>
  );
};

export default MetaOneButton;
