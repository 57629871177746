import React, { Dispatch } from 'react';
interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label?: string | React.ReactElement;
  iconright?: React.ReactElement;
  submitting?: boolean;
  buttonType?: string;
  handleClick?: Dispatch<any>;
}
const SecondaryButton: React.FC<Props> = (props) => {
  const propsButton: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > = {};

  Object.keys(props).forEach(function (key) {
    if (key !== 'title' && key !== 'submitting' && key !== 'button_type') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      propsButton[key] = props[key];
    }
  });
  return (
    <button
      className={`secondary-button ${props.buttonType ? props.buttonType : ''}'}`}
      type="submit"
      onClick={props.handleClick}
      disabled={props.submitting}
      {...propsButton}
    >
      <>
        {props.label}
        {props.iconright}
      </>
    </button>
  );
};

export default SecondaryButton;
