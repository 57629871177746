import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/state';
import { removeGlobalMessage } from 'src/state/app/actions';
import { getGlobalMessages } from 'src/state/app/selectors';
import { Close } from '@material-ui/icons';

const GlobalMessageWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [scheduledExpirations, setScheduledExpirations] = useState<string[]>([]);
  const [hidden, setHidden] = useState<string[]>([]);
  const { errors } = useAppSelector((state) => ({
    errors: getGlobalMessages(state) || [],
  }));

  useEffect(() => {
    errors.forEach((item) => {
      if (!scheduledExpirations.includes(item.uuid)) {
        setScheduledExpirations((items) => [...items, item.uuid]);
        const diff = item.expiresAt.getTime() - new Date().getTime();
        hideError(item.uuid, diff);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const hideError = (uuid: string, diff: number) => {
    setTimeout(() => {
      setHidden((items) => [...items, uuid]);
    }, diff);
    setTimeout(() => {
      dispatch(removeGlobalMessage(uuid));
    }, diff + 1000);
  };

  return (
    <>
      {children}
      <div id="globalErrorWrapper">
        {errors &&
          errors.map((item) => {
            const ih = hidden.includes(item.uuid);
            return (
              <div
                className={`item ${ih ? 'hide-animated' : ''} ${
                  item.isError ? 'error' : 'success'
                }`}
                key={item.uuid}
              >
                <div className="ins">
                  <div className="content">{item.message}</div>
                  <button
                    onClick={() => {
                      hideError(item.uuid, 0);
                    }}
                  >
                    <Close className="close-btn" style={{ color: '#fff', fontSize: 20 }} />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default GlobalMessageWrapper;
