import { createAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { AdditionalVestingType, MainVestingType } from 'src/hooks/useContract';

export const updateVestingContractBalance = createAction<{
  type: MainVestingType;
  data: BigNumber;
}>('vesting/updateVestingContractBalance');

export const updateVestingContractBalanceAdditional = createAction<{
  type: AdditionalVestingType;
  data: BigNumber;
}>('vesting/updateVestingContractBalanceAdditional');
