import INJECTED_ICON_URL from '../assets/images/wallets/arrow-right.svg';
import METAMASK_ICON_URL from '../assets/images/metamask.png';
import WALLETCONNECT_ICON_URL from 'src/assets/images/wallet-connect.png';
import METAONE_ICON_URL from '../assets/images/wallets/metaone-logo.svg';

interface WalletInfo {
  name: string;
  iconURL: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    name: 'Injected',
    iconURL: INJECTED_ICON_URL,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    name: 'MetaMask',
    iconURL: METAMASK_ICON_URL,
    description: 'Metamask browser extension',
    href: 'https://metamask.io/',
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    name: 'WalletConnect',
    iconURL: WALLETCONNECT_ICON_URL,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  METAONE: {
    name: 'Saakuru App',
    iconURL: METAONE_ICON_URL,
    description: 'Saakuru App',
    href: 'https://app.saakuru.com/',
    color: '#E8831D',
  },
};
