import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useAppSelector } from 'src/state';
import { getAAGPrice } from 'src/state/currencies/selectors';

const PriceAAGUSD: React.FC<{ amount: number; minimumFractionDigits?: number }> = ({
  amount,
  minimumFractionDigits,
}) => {
  const { aagPrice } = useAppSelector((state) => ({
    aagPrice: getAAGPrice(state),
  }));
  return (
    <>
      <FormattedNumber
        value={amount * aagPrice}
        style="currency"
        currency={'USD'}
        minimumFractionDigits={minimumFractionDigits || 0}
      />
    </>
  );
};

export default PriceAAGUSD;
