import React, { useEffect, useState } from 'react';
import { SUPPORTED_WALLETS } from 'src/constants/wallet';
import { useAppDispatch } from 'src/state';
import { setAuthorized, setGlobalMessage, toggleAuthorizationModal } from 'src/state/app/actions';
import { AuthorizationMethods, GlobalMessage } from 'src/types';
import { uuid } from 'src/helpers';
import { Subject } from 'rxjs';
import moment from 'moment';
import { switchList } from 'src/connectors/selectNetwork';
import { walletConnectV2HRM } from 'src/connectors/walletConnect2HRm';
import WCButton from './components/WCButton';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { walletConnectV2 } from 'src/connectors/walletConnect2';
import { Spinner } from 'react-bootstrap';

export const ReinitializeWalletConnect = new Subject<() => any>();

const WalletConnectButtonV2: React.FC = () => {
  const dispatch = useAppDispatch();
  const [connecting, setConnecting] = useState<boolean>();
  const [desiredChainId, setDesiredChainId] = useState<number | undefined>(undefined);
  // @ts-ignore
  const isMetaone = !!window?.isMetaOneInjectedBrowser || !!window?.ethereum?.isMetaOne;

  const initWalletConnect = async () => {
    dispatch(setAuthorized(AuthorizationMethods.walletconnect2));
    dispatch(toggleAuthorizationModal(false));
    setDesiredChainId(undefined);
    setConnecting(false);
  };
  useEffect(() => {
    walletConnectV2.connectEagerly();
  }, []);
  const initAuth = async (selectedChain: number) => {
    setDesiredChainId(selectedChain);
    const connector = selectedChain !== 1 ? walletConnectV2HRM : walletConnectV2;
    connector instanceof WalletConnectV2 &&
      void connector
        .activate(selectedChain)
        .then(() => {
          setTimeout(() => {
            connector.activate().then(() => {
              initWalletConnect();
            });
          }, 1000);
        })
        .catch((error: any) => {
          let msg = error?.message || 'Something went wrong';
          if (error?.message.includes('Unsupported')) {
            // TODO show explanatory popup here
            msg =
              'Your wallet is set to incorrect network. Please change it to Ethereum mainnet before logging in';
          }
          const message: GlobalMessage = {
            message: msg,
            uuid: uuid(),
            expiresAt: moment().add(20, 'seconds').toDate(),
            isError: true,
          };
          setConnecting(false);
          setDesiredChainId(undefined);
          dispatch(setAuthorized(undefined));
          dispatch(toggleAuthorizationModal(false));
          dispatch(setGlobalMessage(message));
          connector.provider = undefined;
        });
  };

  useEffect(() => {
    ReinitializeWalletConnect.subscribe((retry) => {
      const connector = walletConnectV2;
      connector.provider = undefined;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initAuth(desiredChainId!);
      setTimeout(() => {
        retry();
      }, 4000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMetaone) {
    return null;
  }

  return (
    <div>
      <div className="wallet-connect-wrapper">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '16px' }}>WalletConnect</span>
          <img
            src={SUPPORTED_WALLETS['WALLET_CONNECT'].iconURL}
            style={{ width: 26, height: 26, borderRadius: '100%' }}
          />
        </div>
        <div className="wc2-wrapper">
          {switchList.map((networkProps) => {
            return (
              <WCButton
                key={networkProps.id}
                networkProps={networkProps}
                desiredChainId={desiredChainId}
                initAuth={initAuth}
                setDesiredChainId={setDesiredChainId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WalletConnectButtonV2;
