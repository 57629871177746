import { Contract } from '@ethersproject/contracts';
import { default as TOKEN_ABI } from 'src/abis/AAGToken.json';
import { default as VESTING_ABI } from 'src/abis/AAGVestingContract.json';
import mainet from '../envs/mainnet/env.json';
import harmony from '../envs/harmonyMainnet/env.json';
import { useMemo } from 'react';
import { getContract } from 'src/utils';
import { AAGToken } from 'src/types/w3/AAGToken';
import { AAGVestingContract } from 'src/types/w3/AAGVestingContract';
import { useAppSelector } from 'src/state';
import { getSelectedPool } from 'src/state/account/selectors';
import useActiveWeb3 from './useActiveWeb3';
import { useWeb3React } from '@web3-react/core';

const getENVBasedOnNetwork = (network: number) => {
  if (network === 1) {
    return mainet;
  } else {
    return harmony;
  }
};

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const { provider, account, chainId } = useWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined,
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return undefined;
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T;
}

export function useTokenContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3React();
  const { TOKEN_CONTRACT_ADDRESS } = getENVBasedOnNetwork(chainId || Number(0));
  return useContract<AAGToken>(TOKEN_CONTRACT_ADDRESS, TOKEN_ABI, withSignerIfPossible);
}

export const MainVestingArray = ['backers', 'ecosystem', 'partnerships', 'team'];

export const AdditionalVestingArray = ['backers10', 'ecosystem10'];

export const VestingContractArray = [...MainVestingArray, ...AdditionalVestingArray];

export enum MainVestingType {
  'backers' = 'backers',
  'ecosystem' = 'ecosystem',
  'partnerships' = 'partnerships',
  'team' = 'team',
}

export enum AdditionalVestingType {
  'backers10' = 'backers10',
  'ecosystem10' = 'ecosystem10',
}
export type VestingContractType = MainVestingType | AdditionalVestingType;

const getContractAddressByType = (type: VestingContractType, chainId?: number) => {
  const config = getENVBasedOnNetwork(chainId || Number(0));
  if (type === 'backers') return config['VESTING_CONTRACT_ADDRESS_BACKERS'];
  if (type === 'backers10') return config['VESTING_CONTRACT_ADDRESS_BACKERS_10'];
  if (type === 'ecosystem') return config['VESTING_CONTRACT_ADDRESS_ECOSYSTEM'];
  if (type === 'ecosystem10') return config['VESTING_CONTRACT_ADDRESS_ECOSYSTEM_10'];
  if (type === 'partnerships') return config['VESTING_CONTRACT_ADDRESS_PARTNERSHIPS'];
  if (type === 'team') return config['VESTING_CONTRACT_ADDRESS_TEAM'];
};

export function useVestingContract(type: VestingContractType) {
  const { chainId } = useWeb3React();
  const address = getContractAddressByType(type, chainId);
  return useContract<AAGVestingContract>(address, VESTING_ABI, true);
}

export function useSelectedVestingContract() {
  const selectedContract = useAppSelector(getSelectedPool);

  const vestingContractBackers = useVestingContract(MainVestingType.backers);
  const vestingContractBackers10 = useVestingContract(AdditionalVestingType.backers10);
  const vestingContractEcosystem = useVestingContract(MainVestingType.ecosystem);
  const vestingContractEcosystem10 = useVestingContract(AdditionalVestingType.ecosystem10);
  const vestingContractPartnerships = useVestingContract(MainVestingType.partnerships);
  const vestingContractTeam = useVestingContract(MainVestingType.team);

  if (selectedContract === 'backers')
    return {
      main: vestingContractBackers,
      additional: vestingContractBackers10,
    };
  if (selectedContract === 'ecosystem')
    return {
      main: vestingContractEcosystem,
      additional: vestingContractEcosystem10,
    };
  if (selectedContract === 'partnerships')
    return {
      main: vestingContractPartnerships,
      additional: undefined,
    };
  if (selectedContract === 'team')
    return {
      main: vestingContractTeam,
      additional: undefined,
    };
  return {
    main: undefined,
    additional: undefined,
  };
}
