import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import { defineMessages, useIntl } from 'react-intl';
import { ReactComponent as Burger } from '../../assets/images/icons/burger.svg';
import { ReactComponent as Telegram } from '../../assets/images/icons/Telegram.svg';

const messages = defineMessages({
  home: {
    id: 'Header.home',
    defaultMessage: 'Home',
  },
  staking: {
    id: 'Header.staking',
    defaultMessage: 'Staking',
  },
  withdrawals: {
    id: 'Header.withdrawals',
    defaultMessage: 'Withdrawals',
  },
  telegramChannel: {
    id: 'Header.telegramChannel',
    defaultMessage: 'Telegram',
  },
  privacyPolicy: {
    id: 'BurgerFooter.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  aag: {
    id: 'BurgerFooter.aag',
    defaultMessage: 'A chip & A chair Guild Ventures Ltd. Pte.',
  },
});

const BurgerMenu: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const intl = useIntl();

  return (
    <div id="HeaderBurderMenu">
      <div className="burder-menu-icon" onClick={() => setOpen(!open)}>
        <Burger />
      </div>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="burger-menu-content-wrapper">
          <div className="burger-menu-content">
            <div className="burger-menu-header">
              <div style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                <AiOutlineClose style={{ margin: '30px 40px 0 0' }} color="white" />
              </div>
            </div>
            <div className="burger-menu-item">
              <a href="/" rel="noreferrer">
                {intl.formatMessage(messages.home)}
              </a>
            </div>
            <div className="burger-menu-item">
              <a
                href="https://www.sushi.com/swap?token0=0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a&token1=0xAE0609A062a4eAED49dE28C5f6A193261E0150eA&chainId=1666600000"
                target="_blank"
                rel="noreferrer"
              >
                Swap
              </a>
            </div>
            <div className="burger-menu-item">
              <a href="https://bridge.harmony.one/erc20" target="_blank" rel="noreferrer">
                Bridge
              </a>
            </div>
            <div className="burger-menu-item">
              <a href="https://staking.aag.ventures" target="_blank" rel="noreferrer">
                {intl.formatMessage(messages.staking)}
              </a>
            </div>
            {/* <div className="burger-menu-item">
              <a href="https://intercom.help/aag-ventures/en" target="_blank" rel="noreferrer">
                Help
              </a>
            </div> */}
            <div className="burger-menu-item">
              <a
                style={{ display: 'flex', alignItems: 'center' }}
                href="https://t.me/aagventures"
                target="_blank"
                rel="noreferrer"
              >
                <Telegram style={{ marginRight: '7px' }} />
                <span>Telegram</span>
              </a>
            </div>
          </div>
          <div className="burger-menu-footer">
            <div>
              © {intl.formatMessage(messages.aag)} {new Date().getFullYear()}
            </div>
            <div>
              <a href="https://saakuru.com/privacy-policy" target="_blank" rel="noreferrer">
                {intl.formatMessage(messages.privacyPolicy)}
              </a>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default BurgerMenu;
