declare let window: any;
import React, { useState, useEffect, useCallback } from 'react';
import { formatedAddress } from 'src/utils';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { BiExit } from 'react-icons/bi';
import { AiOutlineCheck, AiOutlineLink } from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import { defineMessages } from 'react-intl';
import { ReactComponent as ProfileLogo } from 'src/assets/images/user-photo.svg';
import { ReactComponent as AagLogo } from 'src/assets/images/aag-logo-small.svg';
import { useAppDispatch, useAppSelector } from 'src/state';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { getAccountAAGBalance } from 'src/state/account/selectors';
import { setAuthorized, toggleAddTokenModal } from 'src/state/app/actions';
import { addAAGTokenToMetamask } from 'src/helpers/addTokent';
import { Network } from '@web3-react/network';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import useActiveWeb3 from 'src/hooks/useActiveWeb3';
import { useWeb3React } from '@web3-react/core';
import { useTokenContract } from 'src/hooks/useContract';

const messages = defineMessages({
  logout: {
    id: 'WebAddressWidget.logout',
    defaultMessage: 'Logout',
  },
  copyAddress: {
    id: 'WebAddressWidget.copyAddress',
    defaultMessage: 'Copy wallet address',
  },
  viewOnBrowser: {
    id: 'WebAddressWidget.viewOnBrowser',
    defaultMessage: 'View on Explorer',
  },
  copiedToClipboard: {
    id: 'WebAddressWidget.copiedToClipboard',
    defaultMessage: 'Copied to clipboard',
  },
  yourWalletAddressIs: {
    id: 'WebAddressWidget.yourWalletAddressIs',
    defaultMessage: 'Your wallet address',
  },
  currentBalance: {
    id: 'WebAddressWidget.currentBalance',
    defaultMessage: 'Current AAG balance',
  },
});

const Web3AddressWidget: React.FC = () => {
  const dispatch = useAppDispatch();
  const { aagBalance } = useAppSelector((state) => ({
    aagBalance: getAccountAAGBalance(state),
  }));
  const { account, connector, provider, chainId } = useWeb3React();

  const [width, setWidth] = useState(window.innerWidth);
  const [fetching, setFetching] = useState(false);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  const { ETH } = window as any;

  const tokenContract = useTokenContract();

  useEffect(() => {
    if (!provider || !tokenContract || !account || !ETH) return;
    const handleAccountsChanged = async (accounts: string[]) => {
      setFetching(true);
      const data = await tokenContract.balanceOf(accounts[0]);
      if (data) {
        setFetching(false);
      }
    };
    ETH.on('accountsChanged', handleAccountsChanged);
  }, [account, provider, tokenContract, ETH]);
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [copied, setCopy] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const intl = useIntl();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopy(false), 2000);
    }
  }, [copied]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setCopy(false);
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(account || '');
    setCopy(true);
  }, [account]);

  const handleAddTokenModal = () => {
    dispatch(toggleAddTokenModal(true));
    setAnchorEl(null);
    setCopy(false);
  };

  if (!!account) {
    return (
      <div>
        <Button
          id="fade-button"
          aria-controls={open ? 'ConnectedUserWeb3ButtonMenu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ fontFamily: 'Mulish' }}
        >
          <span className="connected-user-button">
            <span className="address">{formatedAddress(account)}</span>
            <ProfileLogo
              style={{ marginLeft: '10px', width: '18px', height: 'auto', borderRadius: '100%' }}
            />
          </span>
        </Button>
        <Menu
          id="ConnectedUserWeb3ButtonMenu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: width <= 600 ? 'center' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: width <= 600 ? 'center' : 'right',
          }}
          PaperProps={{
            style: {
              marginTop: '15px',
              borderRadius: '4px',
              background: '#21203F',
              minWidth: '275px',
              padding: '0px 6px',
            },
          }}
        >
          <div className="connected-user-button-menu">
            <div
              className="connected-user-button-menu-button logout-button"
              onClick={() => {
                handleClose();
                dispatch(setAuthorized(undefined));
                if (connector?.deactivate) {
                  void connector.deactivate();
                } else {
                  void connector.resetState();
                  dispatch(setAuthorized(undefined));
                }
              }}
            >
              <BiExit style={{ marginRight: '10px', fontSize: '14px' }} />
              <span className="font-14">{intl.formatMessage(messages.logout)}</span>
            </div>

            <div className="wallet-info">
              <div className="wallet-info-header">
                {intl.formatMessage(messages.currentBalance)}
              </div>
              <div className="font-16">
                <AagLogo
                  style={{
                    marginRight: '10px',
                    width: '32px',
                    height: '32px',
                  }}
                />
                <span>{`${aagBalance.toFixed(2)} AAG`}</span>

                {chainId ? (
                  <button
                    onClick={() => {
                      window.ethereum
                        ? addAAGTokenToMetamask(chainId, provider?.provider)
                        : handleAddTokenModal();
                    }}
                    className="connected-user-button-menu-button wallet-action-button wallet-addtoken"
                  >
                    Add Token
                  </button>
                ) : null}
              </div>
            </div>
            <div className="wallet-info">
              <div className="wallet-info-header">
                {intl.formatMessage(messages.yourWalletAddressIs)}
              </div>
              <div className="font-16">
                <ProfileLogo
                  style={{
                    marginRight: '10px',
                    width: '32px',
                    height: 'auto',
                    borderRadius: '100%',
                  }}
                />
                {formatedAddress(account)}
              </div>
            </div>
            <div
              className="connected-user-button-menu-button wallet-action-button"
              onClick={() => handleCopyToClipboard()}
            >
              {copied ? (
                <>
                  <AiOutlineCheck style={{ marginRight: '10px' }} />{' '}
                  <span className="font-14">{intl.formatMessage(messages.copiedToClipboard)}</span>
                </>
              ) : (
                <>
                  <FiCopy style={{ marginRight: '10px' }} />{' '}
                  <span className="font-14">{intl.formatMessage(messages.copyAddress)}</span>
                </>
              )}
            </div>
            <div
              style={{ marginTop: '9px' }}
              className="connected-user-button-menu-button wallet-action-button"
            >
              <AiOutlineLink size={15} style={{ marginRight: '10px' }} />{' '}
              <span className="font-14">
                <a
                  href={`https://etherscan.io/address/${account}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage(messages.viewOnBrowser)}
                </a>
              </span>
            </div>
          </div>
        </Menu>
      </div>
    );
  }
  return <></>;
};

export default Web3AddressWidget;
