import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
import { IntlProvider } from 'react-intl';
import langs from './localization';
import { getConvertedLocale } from './helpers';
import AppCointainer from './AppCointainer';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import store from './state';
import { Provider } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Route, Switch, useLocation } from 'react-router-dom';
import MainView from './views/AuthorizedView';
import { NavigationPaths } from './utils/NavigationPaths';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalMessageWrapper from './components/GlobalErrorWrapper';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { MetaMask } from '@web3-react/metamask';
import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask';
import { hooks as walletConnectHooks, walletConnect } from './connectors/walletConnect';
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './connectors/walletConnect2';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import {
  hooks as walletConnectV2HooksHRM,
  walletConnectV2HRM,
} from './connectors/walletConnect2HRm';
import { hooks as gnosisSafeHooks, gnosisSafe } from './connectors/gnosisSafeInitializer';
import type { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { WalletConnect } from '@web3-react/walletconnect';
import { Network } from '@web3-react/network';
const queryClient = new QueryClient();

const App: React.FC = () => {
  const [locale] = useState<string>('en-US');
  const location = useLocation();
  const persistor = persistStore(store);
  const connectors: [MetaMask | GnosisSafe | WalletConnect | WalletConnectV2, Web3ReactHooks][] = [
    [metaMask, metaMaskHooks],
    [walletConnect, walletConnectHooks],
    [walletConnectV2, walletConnectV2Hooks],
    [gnosisSafe, gnosisSafeHooks],
    [walletConnectV2HRM, walletConnectV2HooksHRM],
  ];
  return (
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <IntlProvider locale={locale} messages={langs[getConvertedLocale(locale)]}>
            <Web3ReactProvider connectors={connectors}>
              <QueryClientProvider client={queryClient}>
                <GlobalMessageWrapper>
                  <AppCointainer>
                    <TransitionGroup className="main-transition">
                      <CSSTransition key={location.pathname} classNames="fade" timeout={400}>
                        <Switch location={location}>
                          <Route exact path={NavigationPaths.index} component={MainView} />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  </AppCointainer>
                </GlobalMessageWrapper>
              </QueryClientProvider>
            </Web3ReactProvider>
          </IntlProvider>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  );
};

export default App;
