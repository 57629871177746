import { AddEthereumChainParameter } from '@web3-react/types';

export enum SupportedChainId {
  MAINNET = 1,
  HARMONY = 1666600000,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.HARMONY,
];

interface ChainInfoInterface {
  explorer: AddEthereumChainParameter['blockExplorerUrls'] | string;
  urls: string[];
  label: string;
  symbol: string;
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
}

export const CHAINS: { [key in SupportedChainId]: ChainInfoInterface } = {
  [SupportedChainId.MAINNET]: {
    explorer: 'https://etherscan.io',
    urls: ['https://ethereum.publicnode.com'],
    label: 'Mainnet',
    symbol: 'ETH',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [SupportedChainId.HARMONY]: {
    explorer: 'https://explorer.harmony.one',
    urls: ['https://api.harmony.one'],
    label: 'Harmony ONE',
    symbol: 'ONE',
    nativeCurrency: {
      name: 'Harmony ONE',
      symbol: 'ONE',
      decimals: 18,
    },
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{
  [chainId: number]: string[];
}>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[+chainId as SupportedChainId].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
