import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
// import WalletConnectButton from '../connectionButtons/WalletConnectButton';
import { BsArrowRightShort } from 'react-icons/bs';
import Alert from 'src/components/common/Alert';
import MetaOneButton from '../connectionButtons/MetaOneButton';
import WalletConnectButtonV2 from '../connectionButtons/WalletConnectButtonV2';

const messages = defineMessages({
  header: {
    id: 'AuthorizationModal.other.header',
    defaultMessage: 'Connect wallet',
  },
  dontHaveWallet: {
    id: 'AuthorizationModal.other.dontHaveWallet',
    defaultMessage: 'How to connect Saakuru App?',
  },
  q1: {
    id: 'AuthorizationModal.other.q1',
    defaultMessage: 'Click here to create a Saakuru App',
  },
  q2: {
    id: 'AuthorizationModal.other.q2',
    defaultMessage: 'Saakuru App FAQs',
  },
  l1m: {
    id: 'AuthorizationModal.other.l1m',
    defaultMessage: 'Download and install the Saakuru App on your device.',
  },
  l2m: {
    id: 'AuthorizationModal.other.l2m',
    defaultMessage: 'Navigate to the in app dApp Store.',
  },
  l3m: {
    id: 'AuthorizationModal.other.l3m',
    defaultMessage: 'Click on "AAG Vesting".',
  },
  l4m: {
    id: 'AuthorizationModal.other.l4m',
    defaultMessage: 'Click "Connect" and select your wallet.',
  },
  l5m: {
    id: 'AuthorizationModal.other.l5m',
    defaultMessage: 'Follow the prompts to complete the vesting process and view your rewards.',
  },
});

const OtherBrowserContent: React.FC = () => {
  const intl = useIntl();
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  return (
    <>
      <div className="body">
        <div className="nav-wrapper">
          <MetaOneButton />
          <WalletConnectButtonV2 />
          {/* <WalletConnectButton /> */}
        </div>
      </div>
      <div className="info-button" onClick={() => setInfoOpen(!infoOpen)}>
        {intl.formatMessage(messages.dontHaveWallet)}
      </div>
      {infoOpen && (
        <div>
          <Alert>
            <ol>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l1m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l2m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l3m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l4m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l5m)}</li>
            </ol>
            <div className="d-flex flex-column pt-2">
              <div className="pb-2">
                <a href="https://app.saakuru.com/" target="_blank" rel="noreferrer">
                  <BsArrowRightShort style={{ marginRight: '4px' }} className="purple" />
                  <b>{intl.formatMessage(messages.q1)}</b>
                </a>
              </div>
              <a href="https://app.saakuru.com/faq" target="_blank" rel="noreferrer">
                <BsArrowRightShort style={{ marginRight: '4px' }} className="purple" />
                <b>{intl.formatMessage(messages.q2)}</b>
              </a>
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default OtherBrowserContent;
