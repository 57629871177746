import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { SupportedChainId } from 'src/constants/chains';

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        showQrModal: true,
        projectId: '884ca81b762d4a65958013dd7c56d0a7',
        chains: [SupportedChainId.MAINNET],
        rpcMap: { [SupportedChainId.MAINNET]: 'https://ethereum.publicnode.com' },
      },
    }),
);
