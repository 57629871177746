import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MetamaskButton from '../connectionButtons/MetamaskButton';
// import WalletConnectButton from '../connectionButtons/WalletConnectButton';
import Alert from 'src/components/common/Alert';
import ClickToCopyButton from 'src/components/common/ClickToCopyButton';
import MetaOneButton from '../connectionButtons/MetaOneButton';
import DontHaveCryptoWallet from './DontHaveCryptoWallet';
import WalletConnectButtonV2 from '../connectionButtons/WalletConnectButtonV2';

const messages = defineMessages({
  dontHaveWallet: {
    id: 'AuthorizationModal.mobile.dontHaveWallet',
    defaultMessage: 'Don’t have a crypto wallet?',
  },
  unableConnectMetamask: {
    id: 'AuthorizationModal.mobile.unableConnectMetamask',
    defaultMessage: 'How to connect MetaMask wallet?',
  },
  unableConnectMetaOne: {
    id: 'AuthorizationModal.mobile.unableConnectMetaOne',
    defaultMessage: 'How to connect Saakuru App?',
  },
  boxheader: {
    id: 'AuthorizationModal.mobile.boxheader',
    defaultMessage: 'Don’t have a crypto wallet?',
  },
  q1: {
    id: 'AuthorizationModal.mobile.q1',
    defaultMessage: 'Click here to create a Saakuru App',
  },
  q2: {
    id: 'AuthorizationModal.mobile.q2',
    defaultMessage: 'Saakuru App FAQs',
  },
  l1: {
    id: 'AuthorizationModal.mobile.l1',
    defaultMessage: 'Open the MetaMask application.',
  },
  l2: {
    id: 'AuthorizationModal.mobile.l2',
    defaultMessage: 'Click the 3 layered icon on the top left of your screen.',
  },
  l3: {
    id: 'AuthorizationModal.mobile.l3',
    defaultMessage: 'Click the "Browser" button.',
  },
  l4: {
    id: 'AuthorizationModal.mobile.l4',
    defaultMessage:
      'Inside this MetaMask browser, go to our website address and you will be able to connect MetaMask wallet.',
  },
  l1m: {
    id: 'AuthorizationModal.mobile.l1m',
    defaultMessage: 'Download and install the Saakuru App on your device.',
  },
  l2m: {
    id: 'AuthorizationModal.mobile.l2m',
    defaultMessage: 'Navigate to the in app dApp Store.',
  },
  l3m: {
    id: 'AuthorizationModal.mobile.l3m',
    defaultMessage: 'Click on "AAG Vesting".',
  },
  l4m: {
    id: 'AuthorizationModal.mobile.l4m',
    defaultMessage: 'Click "Connect" and select your wallet.',
  },
  l5m: {
    id: 'AuthorizationModal.mobile.l5m',
    defaultMessage: 'Follow the prompts to complete the vesting process and view your rewards.',
  },
  copyAddress: {
    id: 'AuthorizationModal.mobile.copyAddress',
    defaultMessage: 'Copy',
  },
  copiedToClipboard: {
    id: 'AuthorizationModal.mobile.copiedToClipboard',
    defaultMessage: 'Copied to clipboard',
  },
  address: {
    id: 'AuthorizationModal.mobile.address',
    defaultMessage: 'Address',
  },
  aagStudio: {
    id: 'AuthorizationModal.mobile.aagStudio',
    defaultMessage: 'withdrawals.aag.ventures',
  },
});

const MobileContent: React.FC = () => {
  const intl = useIntl();
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [infoMetaOpen, setMetaInfoOpen] = useState<boolean>(false);
  const [infoMetaOneOpen, setMetaOneInfoOpen] = useState<boolean>(false);

  return (
    <>
      <div className="body">
        <div className="nav-wrapper">
          <MetaOneButton />
          <MetamaskButton />
          <WalletConnectButtonV2 />
          {/* <WalletConnectButton /> */}
        </div>
      </div>
      <div className="info-button" onClick={() => setMetaOneInfoOpen(!infoMetaOneOpen)}>
        {intl.formatMessage(messages.unableConnectMetaOne)}
      </div>
      {infoMetaOneOpen && (
        <div className="pt-2 pb-2">
          <Alert>
            <ol>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l1m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l2m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l3m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l4m)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l5m)}</li>
            </ol>
          </Alert>
        </div>
      )}
      <div className="info-button" onClick={() => setMetaInfoOpen(!infoMetaOpen)}>
        {intl.formatMessage(messages.unableConnectMetamask)}
      </div>
      {infoMetaOpen && (
        <div className="pt-2 pb-2">
          <Alert>
            <ol>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l1)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l2)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l3)}</li>
              <li className="wallet-info-content pt-2">{intl.formatMessage(messages.l4)}</li>
            </ol>
            <ClickToCopyButton
              header={intl.formatMessage(messages.address)}
              text={intl.formatMessage(messages.aagStudio)}
              dataToCopy={window.location.href || ''}
            />
          </Alert>
        </div>
      )}
      <div className="info-button" onClick={() => setInfoOpen(!infoOpen)}>
        {intl.formatMessage(messages.dontHaveWallet)}
      </div>
      {infoOpen && (
        <div className="pt-2">
          <DontHaveCryptoWallet />
        </div>
      )}
    </>
  );
};

export default MobileContent;
