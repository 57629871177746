import React from 'react';

const LabelMeanings = () => {
  return (
    <div className="label-meanings">
      <span>
        Vesting is the process of releasing tokens that have been set aside for a specified period
        of time.
      </span>
      <span style={{ display: 'block', marginTop: '10px' }}>
        Progress bar is showing how much tokens have already been vested and has been claimed or is
        available to claim.
      </span>
      <b>Label meanings:</b>
      <div className="status inactive">
        <span>Not scheduled</span>
      </div>
      <span>Vesting process has not been scheduled.</span>
      <div className="status planned">Scheduled</div>
      <span>
        Vesting process has been scheduled and is waiting for Start Date to start vesting.
      </span>
      <div className="status active">Vesting</div>
      <span>Vesting process is live until the End Date.</span>
      <div className="status completed">Completed</div>
      <span>
        Vesting process has been finished. Total amount of your tokens is available to claim.
      </span>
    </div>
  );
};

export default LabelMeanings;
