import { createAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { AdditionalVestingType, MainVestingType } from 'src/hooks/useContract';
import { VestingInterface } from './types';

export const updateAccountBalance = createAction<{ balance: number }>(
  'account/updateAccountBalance',
);

export const updateMainVestingInfo = createAction<{
  type: MainVestingType;
  data: VestingInterface;
}>('account/updateMainVestingInfo');

export const updateAdditionalVestingInfo = createAction<{
  type: AdditionalVestingType;
  data: VestingInterface;
}>('account/updateAdditionalVestingInfo');

export const updateAvailableAmount = createAction<{
  type: MainVestingType;
  data: BigNumber;
}>('account/updateAvailableAmount');

export const updateAvailableAmountAdditional = createAction<{
  type: AdditionalVestingType;
  data: BigNumber;
}>('account/updateAvailableAmountAdditional');

export const setSelectedPool = createAction<MainVestingType>('account/setSelectedPool');
