import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { aggregateRawVestingData } from 'src/helpers/dataAggregators/vestingData';
import useActiveWeb3 from 'src/hooks/useActiveWeb3';
import {
  AdditionalVestingArray,
  AdditionalVestingType,
  MainVestingArray,
  MainVestingType,
  useVestingContract,
} from 'src/hooks/useContract';
import { useAppDispatch, useAppSelector } from 'src/state';
import {
  updateAdditionalVestingInfo,
  updateAvailableAmount,
  updateAvailableAmountAdditional,
  updateMainVestingInfo,
} from 'src/state/account/actions';
import { getDataRefreshed } from 'src/state/app/selectors';
import { useWeb3React } from '@web3-react/core';

const VestingDataHandler = () => {
  const { account, chainId } = useWeb3React();

  const dispatch = useAppDispatch();
  const vestingContractBackers = useVestingContract(MainVestingType.backers);
  const vestingContractBackers10 = useVestingContract(AdditionalVestingType.backers10);
  const vestingContractEcosystem = useVestingContract(MainVestingType.ecosystem);
  const vestingContractEcosystem10 = useVestingContract(AdditionalVestingType.ecosystem10);
  const vestingContractPartnerships = useVestingContract(MainVestingType.partnerships);
  const vestingContractTeam = useVestingContract(MainVestingType.team);

  const { dataRefreshed } = useAppSelector((state) => ({
    dataRefreshed: getDataRefreshed(state),
  }));

  const setupUserData = async (address: string | undefined | null) => {
    if (setUserDataMutation.isLoading) return;
    if (!address) return;
    try {
      // Fetching Main vesting data
      const vestingsData = await Promise.all([
        vestingContractBackers?.vestingScheduleForBeneficiary(address),
        vestingContractEcosystem?.vestingScheduleForBeneficiary(address),
        vestingContractPartnerships?.vestingScheduleForBeneficiary(address),
        vestingContractTeam?.vestingScheduleForBeneficiary(address),
      ]);
      vestingsData.forEach((i, index) => {
        if (!!i && i._startTimestamp !== undefined) {
          dispatch(
            updateMainVestingInfo({
              type: MainVestingArray[index] as MainVestingType,
              data: aggregateRawVestingData(i),
            }),
          );
        }
      });

      // Fetching additional vesting data
      const vestingAdditionalData = await Promise.all([
        vestingContractBackers10?.vestingScheduleForBeneficiary(address),
        vestingContractEcosystem10?.vestingScheduleForBeneficiary(address),
      ]);

      vestingAdditionalData.forEach((i, index) => {
        if (!!i && i._startTimestamp !== undefined) {
          dispatch(
            updateAdditionalVestingInfo({
              type: AdditionalVestingArray[index] as AdditionalVestingType,
              data: aggregateRawVestingData(i),
            }),
          );
        }
      });

      // Fetching Main vesting data
      const availableWithdrawAmountData = await Promise.all([
        vestingContractBackers?.getAvailableWithdrawAmountForAddress(address),
        vestingContractEcosystem?.getAvailableWithdrawAmountForAddress(address),
        vestingContractPartnerships?.getAvailableWithdrawAmountForAddress(address),
        vestingContractTeam?.getAvailableWithdrawAmountForAddress(address),
      ]);

      availableWithdrawAmountData.forEach((i, index) => {
        if (!!i) {
          dispatch(
            updateAvailableAmount({
              type: MainVestingArray[index] as MainVestingType,
              data: i,
            }),
          );
        }
      });

      // Fetching Main vesting data
      const availableWithdrawAmountDataAdditional = await Promise.all([
        vestingContractBackers10?.getAvailableWithdrawAmountForAddress(address),
        vestingContractEcosystem10?.getAvailableWithdrawAmountForAddress(address),
      ]);

      availableWithdrawAmountDataAdditional.forEach((i, index) => {
        if (!!i) {
          dispatch(
            updateAvailableAmountAdditional({
              type: AdditionalVestingArray[index] as AdditionalVestingType,
              data: i,
            }),
          );
        }
      });
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const setUserDataMutation = useMutation(setupUserData);

  useEffect(() => {
    setUserDataMutation.mutate(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);

  useEffect(() => {
    if (dataRefreshed) {
      setUserDataMutation.mutate(account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRefreshed]);

  return <></>;
};

export default VestingDataHandler;
