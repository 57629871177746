import { Alert, Snackbar } from '@mui/material';
import React, { Dispatch } from 'react';

interface Props {
  onClose: Dispatch<any>;
  bgColor: string;
  text: string;
  type: string;
}

const AlertMessage = (props: Props) => {
  return (
    <Snackbar open={true} autoHideDuration={3000} onClose={props.onClose}>
      <Alert
        variant="filled"
        severity={props.type === 'success' ? 'success' : 'error'}
        sx={{ background: props.bgColor }}
      >
        {props.text}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
