export enum AuthorizationMethods {
  walletconnect = 'walletconnect',
  walletconnect2 = 'walletconnect2',
  metamask = 'metamask',
  gnosis = 'gnosis',
  metaone = 'metaone',
}

export interface GlobalMessage {
  message: string;
  uuid: string;
  expiresAt: Date;
  isError?: boolean;
}
