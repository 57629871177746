import React, { ReactNode, ReactPortal, useState } from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as Info } from '../assets/images/icons/info.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { isMobile } from 'react-device-detect';

interface Props {
  tooltip: ReactPortal | ReactNode;
  width?: number;
}

const TooltipC = (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const desktopTheme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          width: props.width || 300,
          fontSize: '14px',
          backgroundColor: '#21203F',
          borderRadius: '4px',
          padding: '16px 22px',
          fontFamily: "'Space Grotesk', sans-serif",
        },
      },
    },
  });

  const mobileTheme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '14px',
          backgroundColor: '#21203F',
          borderRadius: '4px',
          padding: '16px 50px',
          fontFamily: "'Space Grotesk', sans-serif",
          position: 'absolute',
          left: '-230px',
          width: '270px',
        },
      },
    },
  });

  return isMobile ? (
    <MuiThemeProvider theme={mobileTheme}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<div style={{ lineHeight: '22px' }}>{props.tooltip}</div>}
        >
          <Info onClick={handleTooltipOpen} style={{ cursor: 'pointer', marginLeft: '10px' }} />
        </Tooltip>
      </ClickAwayListener>
    </MuiThemeProvider>
  ) : (
    <MuiThemeProvider theme={desktopTheme}>
      <Tooltip title={<div style={{ lineHeight: '22px' }}>{props.tooltip}</div>}>
        <Info style={{ cursor: 'pointer', marginLeft: '10px' }} />
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default TooltipC;
