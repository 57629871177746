import React from 'react';
import Logo from 'src/assets/images/saakuru-logo.svg';
import Uniswap from 'src/assets/images/logos/sociaNetworks/uniswap1.svg';
import Sushi from 'src/assets/images/logos/sociaNetworks/sushi1.svg';
import GateIO from 'src/assets/images/logos/sociaNetworks/gateio.svg';
import MexC from 'src/assets/images/logos/sociaNetworks/mexc36.svg';
import AAGCoinIcon from 'src/assets/images/agg_coin_icon.svg';
import CoinGecko from 'src/assets/images/logos/sociaNetworks/coingecko.svg';
import CMC from 'src/assets/images/logos/sociaNetworks/cmc.svg';
import styled from 'styled-components/macro';
import PriceAAGUSD from './currencies/PriceAAGUSD';
import { getAAGPriceChange } from 'src/state/currencies/selectors';
import { useAppSelector } from 'src/state';

interface Props {
  width: string;
  header: string;
  children: any;
}

interface TextProps {
  setOpacity?: string;
  setColor?: string;
  $padding?: string;
  $color?: string;
  children: any;
}

export const poppinsFontFamily = {
  fontFamily: `Poppins, sans-serif`,
};

export const Numbers_3M = styled.div<{ $color?: string }>`
  /* font-family: 'Manrope', sans-serif; */
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: ${({ $color }) => ($color ? $color : 'white')};
`;
export const Numbers_4 = styled.div<{ $color?: string }>`
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${({ $color }) => ($color ? $color : 'white')};
`;

export const P0_R = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  color: 'rgba(255, 255, 255, 0.6)';
`;
export const P0_SB = styled.div<{ $weight?: string; $color?: string }>`
  font-size: 18px;
  font-weight: ${({ $weight }) => ($weight ? $weight : '300')};
  line-height: 30.2px;
  color: ${({ $color }) => ($color ? $color : 'rgba(255, 255, 255, 1)')};
`;
export const P1_M = styled.div<{ $fontSize?: string; $color?: string }>`
  font-weight: 500;
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')};
  line-height: 25.6px;
  color: ${({ $color }) => ($color ? $color : 'rgba(255, 255, 255, 1)')};
  ${poppinsFontFamily}
`;
export const P1_R = styled.div<TextProps>`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  opacity: ${({ setOpacity }) => (setOpacity ? setOpacity : '')};
  padding: ${({ $padding }) => ($padding ? $padding : '')};
  color: ${({ $color }) => ($color ? $color : 'rgba(255, 255, 255, 1)')};
  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
  }
`;
export const P1_SB = styled.div<{ $color?: string }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 2 5.6px;
  color: ${({ $color }) => ($color ? $color : 'rgba(255, 255, 255, 1)')};
`;
export const P2_M = styled.div<{ $color?: string; $lineHeight?: string }>`
  font-weight: 500;
  font-size: 14px;
  line-height: ${({ $lineHeight }) => ($lineHeight ? $lineHeight : '22.4px')};
  color: ${({ $color }) => ($color ? $color : 'rgba(255, 255, 255, 1)')};
`;
export const P2_R = styled.div<TextProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: ${({ setColor }) => (setColor ? setColor : 'rgba(255, 255, 255, 1)')};
`;
export const P2_SB = styled.div<TextProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 22.4px;
  color: ${({ setColor }) => (setColor ? setColor : 'rgba(255, 255, 255, 1)')};
`;
export const P3_R = styled.div<TextProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ setColor }) => (setColor ? setColor : '')};
  opacity: ${({ setOpacity }) => (setOpacity ? setOpacity : '')};
`;
export const P3_M = styled.div<{ $color?: string; $lineHeight?: string }>`
  font-weight: 500;
  font-size: 12px;
  line-height: ${({ $lineHeight }) => ($lineHeight ? $lineHeight : '16.8px')};
  color: ${({ $color }) => ($color ? $color : '')};
`;

export const B3_R_R = styled.div<{ $color?: string }>(({ $color }) => ({
  display: 'flex',
  placeItems: 'center',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  color: $color ? $color : 'rgba(255, 255, 255, 1)',
}));

export const B3_R_B = styled.div<{ $color?: string; $opacity?: string }>(
  ({ $color, $opacity }) => ({
    display: 'flex',
    placeItems: 'center',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    color: $color ? $color : 'rgba(255, 255, 255, 1)',
    opacity: $opacity ? $opacity : '1',
  }),
);

export const Label2_B = styled.div<{ $color?: string }>(({ $color }) => ({
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '6.5px',
  color: $color ? $color : 'rgba(255, 255, 255, 1)',
}));

export const TokenPriceWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const RectangleInfoPrice = styled.div<{
  $width?: string;
  $height?: string;
  $padding?: string;
  $marginBtm?: string;
  disableHover?: boolean;
  isBackgroundColor?: boolean;
}>`
  background-color: ${({ isBackgroundColor }) => !isBackgroundColor && 'rgba(255, 255, 255, 0.15)'};
  border-radius: 4px;
  width: ${({ $width }) => ($width ? $width : '160px')};
  height: ${({ $height }) => ($height ? $height : '40px')};
  padding: ${({ $padding }) => ($padding ? $padding : '6px 10px')};
  margin-bottom: ${({ $marginBtm }) => ($marginBtm ? $marginBtm : '20px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  div {
    display: flex;
    svg {
      margin-right: 8px;
    }
  }
  &:hover {
    background-color: ${({ disableHover }) => !disableHover && 'rgba(255, 255, 255, 0.6)'};
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 100px;
  margin: 0 auto;
  max-width: 1760px;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
  place-content: center;
  justify-content: space-between;
  position: relative;
  @media (max-width: 580px) {
    flex-direction: column;
  }
  @media (max-width: 1360px) {
    /* justify-content: space-evenly; */
  }
  @media (max-width: 916px) {
    padding: 100px;
    justify-content: flex-start;
  }
  @media (max-width: 800px) {
    padding: 100px;
    justify-content: flex-start;
  }
  @media (max-width: 581px) {
    padding: 40px;
    place-content: flex-start;
  }
`;

const StyledLogoWrapper = styled.div`
  max-width: 220px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledFooterSection = styled.div<{ width: string }>`
  gap: 40px;
  width: ${({ width }) => (width ? width : '200px')};
  @media (max-width: 1050px) {
    width: 200px;
  }
`;

const SectionItem = styled.div`
  display: flex;
  gap: 14px;
  height: 50px;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const ExchangeItemWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
`;

export const ExchangeIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
`;

const FooterElementWrapper = styled.div`
  display: flex;
  gap: 40px;
  place-content: space-between;
  @media (max-width: 1360px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (max-width: 580px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const FooterSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1360px) {
    display: block;
  }
`;

const FooterSection: React.FC<Props> = ({ header, children, width }) => {
  return (
    <StyledFooterSection width={width}>
      <P2_M $color={'rgba(255, 255, 255, 0.6)'} style={{ marginBottom: '20px' }}>
        {header}
      </P2_M>
      {children}
    </StyledFooterSection>
  );
};

const Footer: React.FC = () => {
  const { aagPriceChange } = useAppSelector((state) => ({
    aagPriceChange: getAAGPriceChange(state),
  }));

  return (
    <FooterWrapper>
      <StyledLogoWrapper>
        <img src={Logo} alt="Saakuru Logo" style={{ width: '160px', height: '40px' }} />
        <P1_R style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          © Achip & Achair Global. {new Date().getFullYear()}
        </P1_R>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <a
            href="https://saakuru.com/privacy-policy/"
            style={{ opacity: 1, color: '#fff' }}
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            href={'https://saakuru.com/terms-and-conditions'}
            style={{ opacity: 1, color: '#fff' }}
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </div>
      </StyledLogoWrapper>
      <FooterSectionContainer>
        <FooterElementWrapper>
          <FooterSection header="Token" width="200px">
            <div>
              <TokenPriceWrapper>
                <RectangleInfoPrice
                  disableHover
                  $marginBtm={'0px'}
                  isBackgroundColor
                  $padding={'0px'}
                  $height="38px"
                  $width="200px"
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      src={AAGCoinIcon}
                      alt="AAG Coin Icon"
                      style={{ width: '18px', height: '18px' }}
                    />
                    <P1_M style={{ marginLeft: '6px' }}>AAG</P1_M>
                  </div>
                  <Numbers_3M>
                    <PriceAAGUSD amount={1} minimumFractionDigits={4} />
                  </Numbers_3M>
                  <Numbers_4 $color={aagPriceChange > 0 ? 'rgba(47, 215, 134, 1)' : '#F82525'}>
                    {aagPriceChange > 0 && '+'}
                    {aagPriceChange?.toFixed(2)}%
                  </Numbers_4>
                </RectangleInfoPrice>
              </TokenPriceWrapper>
              <SectionItem>
                <a
                  style={{ display: 'flex', gap: '10px' }}
                  href="https://coinmarketcap.com/currencies/aag-ventures/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={CMC} alt="AAG CoinMarketCap" />
                  <P1_M>CoinMarketCap</P1_M>
                </a>
              </SectionItem>
              <SectionItem>
                <a
                  style={{ display: 'flex', gap: '10px' }}
                  href="https://www.coingecko.com/en/coins/aag-ventures"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={CoinGecko} alt="AAG CoinGecko" />
                  <P1_M>CoinGecko</P1_M>
                </a>
              </SectionItem>
              <SectionItem>
                <a href="https://staking.aag.ventures/" target="_blank" rel="noreferrer">
                  <P1_M>Stake</P1_M>
                </a>
              </SectionItem>
              <SectionItem>
                <a href="https://bridge.harmony.one/erc20" target="_blank" rel="noreferrer">
                  <P1_M>Bridge</P1_M>
                </a>
              </SectionItem>
            </div>
          </FooterSection>
          <FooterSection header="Trade" width="200px">
            <ExchangeItemWrapper>
              <ExchangeIconWrapper>
                <img src={Uniswap} alt="AAG Uniswap" />
              </ExchangeIconWrapper>
              <a
                href="https://v2.info.uniswap.org/token/0x5ba19d656b65f1684cfea4af428c23b9f3628f97"
                target="_blank"
                rel="noreferrer"
              >
                <P1_M>Uniswap</P1_M>
                <P3_R setColor="#94A3B8" style={{ opacity: '0.6' }}>
                  Swap AAG with ETH
                </P3_R>
              </a>
            </ExchangeItemWrapper>
            <ExchangeItemWrapper>
              <ExchangeIconWrapper>
                <img src={Sushi} alt="AAG Sushi" />
              </ExchangeIconWrapper>
              <a
                href="https://www.sushi.com/swap?fromCurrency=0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a&fromChainId=1666600000&toCurrency=0xAE0609A062a4eAED49dE28C5f6A193261E0150eA&toChainId=1666600000"
                target="_blank"
                rel="noreferrer"
              >
                <P1_M>Sushi</P1_M>
                <P3_R setColor="#94A3B8" style={{ opacity: '0.6' }}>
                  Swap AAG with ONE
                </P3_R>
              </a>
            </ExchangeItemWrapper>
            <ExchangeItemWrapper>
              <ExchangeIconWrapper>
                <img src={GateIO} alt="AAG Gate.io" />
              </ExchangeIconWrapper>
              <a
                href="https://www.gate.io/trade/AAG_USDT?ref=10202346"
                target="_blank"
                rel="noreferrer"
              >
                <P1_M>Gate.io</P1_M>
                <P3_R setColor="#94A3B8" style={{ opacity: '0.6' }}>
                  Trade AAG
                </P3_R>
              </a>
            </ExchangeItemWrapper>
            <ExchangeItemWrapper>
              <ExchangeIconWrapper>
                <img src={MexC} alt="AAG MexC" />
              </ExchangeIconWrapper>
              <a
                href="https://www.mexc.com/exchange/AAG_USDT?inviteCode=mexc-1SodZ"
                target="_blank"
                rel="noreferrer"
              >
                <P1_M>MexC</P1_M>
                <P3_R setColor="#94A3B8" style={{ opacity: '0.6' }}>
                  Trade AAG
                </P3_R>
              </a>
            </ExchangeItemWrapper>
          </FooterSection>
          <FooterSection header="Misc" width="160px">
            <a href="https://saakuru.com" target="_blank" rel="noreferrer">
              <SectionItem>
                <P1_M>Saakuru Labs</P1_M>
              </SectionItem>
            </a>
            <a href="https://app.saakuru.com" target="_blank" rel="noreferrer">
              <SectionItem>
                <P1_M>Saakuru App</P1_M>
              </SectionItem>
            </a>

            <a href="https://saakuru.com/gaming" target="_blank" rel="noreferrer">
              <SectionItem>
                <P1_M>Gaming</P1_M>
              </SectionItem>
            </a>
            <SectionItem>
              <a href={'https://saakuru.com/about-us'} target="_blank" rel="noreferrer">
                <P1_M>About us</P1_M>
              </a>
            </SectionItem>
            <a href="https://saakuru.com/academy/" target="_blank" rel="noreferrer">
              <SectionItem>
                <P1_M>Academy</P1_M>
              </SectionItem>
            </a>
            <SectionItem>
              <a href={'https://saakuru.com/careers'} target="_blank" rel="noreferrer">
                <P1_M>Careers</P1_M>
              </a>
            </SectionItem>
            <a href="https://blog.saakuru.com/" target="_blank" rel="noreferrer">
              <SectionItem>
                <P1_M>Blog</P1_M>
              </SectionItem>
            </a>
            <SectionItem>
              <a href={'https://saakuru.com/brandkit'} target="_blank" rel="noreferrer">
                <P1_M>Brand kit</P1_M>
              </a>
            </SectionItem>
          </FooterSection>
          <FooterSection header="AAG" width="160px">
            <SectionItem>
              <a href="https://www.facebook.com/saakurulabs" target="_blank" rel="noreferrer">
                <P1_M>Facebook</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a
                href="https://www.linkedin.com/company/saakurulabs/"
                target="_blank"
                rel="noreferrer"
              >
                <P1_M>LinkedIn</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://discord.gg/saakuru" target="_blank" rel="noreferrer">
                <P1_M>Discord</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://blog.saakuru.com" target="_blank" rel="noreferrer">
                <P1_M>Medium</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://twitter.com/saakuru_labs" target="_blank" rel="noreferrer">
                <P1_M>Twitter</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://t.me/aagventures" target="_blank" rel="noreferrer">
                <P1_M>Telegram</P1_M>
              </a>
            </SectionItem>
          </FooterSection>
          <FooterSection header="Community" width="160px">
            <SectionItem>
              <a href="https://linktr.ee/aagcommunity/" target="_blank" rel="noreferrer">
                <P1_M>English</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://linktr.ee/aag.indonesiacommunity/" target="_blank" rel="noreferrer">
                <P1_M>Indonesian</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://linktr.ee/aagcommunitybr" target="_blank" rel="noreferrer">
                <P1_M>Portuguese</P1_M>
              </a>
            </SectionItem>
            <SectionItem>
              <a href="https://linktr.ee/aagcommunityes" target="_blank" rel="noreferrer">
                <P1_M>Spanish</P1_M>
              </a>
            </SectionItem>
          </FooterSection>
        </FooterElementWrapper>
      </FooterSectionContainer>
    </FooterWrapper>
  );
};

export default Footer;
