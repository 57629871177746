import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Alert from 'src/components/common/Alert';
import { BsArrowRightShort } from 'react-icons/bs';

const messages = defineMessages({
  dontHaveWallet: {
    id: 'DontHaveCryptoWallet.chrome.dontHaveWallet',
    defaultMessage: 'Don’t have a crypto wallet?',
  },
  p1: {
    id: 'DontHaveCryptoWallet.chrome.p1',
    defaultMessage:
      'We recommend that you create a Saakuru App because it is easy to use, secure, and provides a seamless experience.',
  },
  p2: {
    id: 'DontHaveCryptoWallet.chrome.p2',
    defaultMessage: 'With a Saakuru App, you can connect to AAG Vesting in 3 quick steps:',
  },
  p3: {
    id: 'DontHaveCryptoWallet.chrome.p3',
    defaultMessage: 'Navigate to the in app dApp Store.',
  },
  p4: {
    id: 'DontHaveCryptoWallet.chrome.p4',
    defaultMessage: 'Click on "AAG Vesting".',
  },
  p5: {
    id: 'DontHaveCryptoWallet.chrome.p5',
    defaultMessage: 'Click "Connect" and select your wallet.',
  },
  p6: {
    id: 'DontHaveCryptoWallet.chrome.p6',
    defaultMessage: 'Follow the prompts to complete the vesting process and view your rewards.',
  },
  q1: {
    id: 'DontHaveCryptoWallet.chrome.q1',
    defaultMessage: 'Click here to create a Saakuru App',
  },
  q2: {
    id: 'DontHaveCryptoWallet.chrome.q2',
    defaultMessage: 'Saakuru App FAQs',
  },
});

const DontHaveCryptoWallet: React.FC = () => {
  const intl = useIntl();
  return (
    <Alert>
      <div className="wallet-info-content">{intl.formatMessage(messages.p1)}</div>
      <div className="wallet-info-content pt-2">{intl.formatMessage(messages.p2)}</div>
      <ol className="wallet-info-content pt-2">
        <li>{intl.formatMessage(messages.p3)}</li>
        <li>{intl.formatMessage(messages.p4)}</li>
        <li>{intl.formatMessage(messages.p5)}</li>
      </ol>
      <div className="wallet-info-content">{intl.formatMessage(messages.p6)}</div>
      <div className="d-flex flex-column pt-2">
        <div className="pb-2">
          <a href="https://app.saakuru.com/" target="_blank" rel="noreferrer">
            <BsArrowRightShort style={{ marginRight: '4px' }} className="purple" />
            <b>{intl.formatMessage(messages.q1)}</b>
          </a>
        </div>

        <a href="https://app.saakuru.com/faq" target="_blank" rel="noreferrer">
          <BsArrowRightShort style={{ marginRight: '4px' }} className="purple" />
          <b>{intl.formatMessage(messages.q2)}</b>
        </a>
      </div>
    </Alert>
  );
};

export default DontHaveCryptoWallet;
