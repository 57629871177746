import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { MainVestingType, useSelectedVestingContract } from 'src/hooks/useContract';
import { useAppSelector } from 'src/state';
import { removeActiveHash, setActiveHash } from 'src/state/app/actions';
import {
  getSelectedAvailableAmount,
  getSelectedPool,
  getSelectedVestingInfo,
} from 'src/state/account/selectors';
import { refreshData } from 'src/state/app/actions';
import { getActiveHash } from 'src/state/app/selectors';
import useActiveWeb3 from 'src/hooks/useActiveWeb3';
import { useWeb3React } from '@web3-react/core';

const ClaimButton: React.FC<{ action: () => unknown; claiming: boolean }> = ({
  action,
  claiming,
}) => (
  <div
    className="claim-widget"
    style={{
      minWidth: 200,
      paddingLeft: 6,
      paddingRight: 6,
      width: '100%',
    }}
  >
    <button className={`primary-button claim-btn md-width`} onClick={action}>
      Claim
      {claiming ? (
        <Spinner animation={'border'} style={{ width: 16, height: 16, marginLeft: 14 }} />
      ) : (
        ''
      )}
    </button>
  </div>
);

const ClaimWidgetMain: React.FC = () => {
  const dispatch = useDispatch();
  const { provider } = useWeb3React();

  const vestingContract = useSelectedVestingContract().main;

  const { availableAmountForAddress, pool, isClaimed } = useAppSelector((state) => {
    const selectedPool = getSelectedVestingInfo(state);
    return {
      availableAmountForAddress: getSelectedAvailableAmount(state),
      pool: getSelectedPool(state) as MainVestingType,
      isClaimed:
        selectedPool._amount.eq(selectedPool._totalDrawn) &&
        selectedPool._totalDrawn.gt(BigNumber.from(0)),
    };
  });

  const { hash } = useAppSelector((state) => ({
    hash: getActiveHash(state, pool),
  }));

  const [claiming, setClaiming] = useState(false);

  const claimAllocation = async () => {
    if (claiming || !vestingContract) return;
    setClaiming(true);
    try {
      const tx = await vestingContract?.withdraw();
      if (tx?.hash) {
        dispatch(setActiveHash({ type: pool, hash: tx.hash }));
      }
      setClaiming(false);
    } catch (e: any) {
      setClaiming(false);
      if (e?.reason) {
        alert(e?.reason);
      } else if (e?.message) {
        alert(e?.message);
      } else if (typeof e === 'object' && e?.error?.data?.originalError?.message) {
        alert(e?.error?.data?.originalError?.message);
      } else if (typeof e === 'object' && e?.message) {
        alert(e?.error?.data?.originalError?.message);
      }
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (hash) {
      interval = setInterval(() => {
        if (provider) {
          provider
            .getTransactionReceipt(hash)
            .then((receipt: any) => {
              if (receipt) {
                if (Number(receipt?.confirmations) > 0) {
                  alert('Transaction confirmed');
                  dispatch(removeActiveHash(pool));
                  clearInterval(interval);
                  dispatch(refreshData(new Date()));
                }
              }
            })
            .catch(() => {
              alert('Transaction failed');
              clearInterval(interval);
              dispatch(removeActiveHash(pool));
              dispatch(refreshData(new Date()));
            });
        }
      }, 3000);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  if (!vestingContract) return <></>;

  return (
    <div>
      {hash ? (
        <div className="claim-block">
          <div style={{ wordWrap: 'anywhere' as any }}>
            <span>Waiting for transaction to be approved</span>
            <br />
            <a
              href={`https://etherscan.io/tx/${hash}`}
              target="_blank"
              rel="noreferrer"
            >{`https://etherscan.io/tx/${hash}`}</a>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <Spinner animation={'border'} />
          </div>
        </div>
      ) : (
        <>
          <>
            <div className="claim-block">
              <div className="d-flex justify-content-center w-full">
                <>
                  {isClaimed ? (
                    <div className="status completed">
                      <span>Claimed</span>
                    </div>
                  ) : availableAmountForAddress?.gt(BigNumber.from(0)) ? (
                    <ClaimButton action={() => claimAllocation()} claiming={claiming} />
                  ) : (
                    <button className={`primary-button claim-btn md-width disabled`}>Claim</button>
                  )}
                </>
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default ClaimWidgetMain;
