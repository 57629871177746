import React from 'react';
import InfoIcon from './InfoIcon';

interface Props {
  children?: React.ReactNode;
  error?: boolean;
}

const Alert: React.FC<Props> = ({ children, error }) => {
  return (
    <div className={`alert-box-wrapper ${error ? 'error-alert' : ''}`}>
      <InfoIcon color="info-icon-purple" />
      <div style={{ marginTop: -4 }}>{children}</div>
    </div>
  );
};

export default Alert;
