import { createReducer } from '@reduxjs/toolkit';
import {
  refreshData,
  setAuthorized,
  toggleAuthorizationModal,
  toggleOnboardingModal,
  setGlobalMessage,
  removeGlobalMessage,
  toggleClaimRewardsModal,
  setAlertMessage,
  setActiveHash,
  removeActiveHash,
  toggleAddTokenModal,
} from './actions';
import { AppState } from './types';

export const appInitialState: AppState = {
  authorizationModalOpen: false,
  onboardingModalOpen: false,
  claimRewardsModalOpen: false,
  isAuthorized: undefined,
  refreshedAt: undefined,
  messages: [],
  alertMessage: undefined,
  backersactiveHashes: undefined,
  ecosystemactiveHashes: undefined,
  backers10activeHashes: undefined,
  ecosystem10activeHashes: undefined,
  partnershipsactiveHashes: undefined,
  teamactiveHashes: undefined,
  tokenAAGmodalVisible: false,
};

export default createReducer(appInitialState, (builder) =>
  builder
    .addCase(toggleAuthorizationModal, (state, action) => {
      state.authorizationModalOpen = action.payload;
    })
    .addCase(setGlobalMessage, (state, action) => {
      state.messages = [action.payload];
    })
    .addCase(removeGlobalMessage, (state, action) => {
      state.messages = state.messages.filter((item) => item.uuid !== action.payload);
    })
    .addCase(toggleOnboardingModal, (state, action) => {
      state.onboardingModalOpen = action.payload;
    })
    .addCase(toggleClaimRewardsModal, (state, action) => {
      state.claimRewardsModalOpen = action.payload;
    })
    .addCase(setAlertMessage, (state, action) => {
      state.alertMessage = action.payload;
    })
    .addCase(setAuthorized, (state, action) => {
      if (action.payload) {
        state.isAuthorized = action.payload;
      } else {
        Object.keys(state).forEach((key) => {
          // @ts-ignore
          state[key] = appInitialState[key];
        });
      }
    })
    .addCase(refreshData, (state, action) => {
      state.refreshedAt = action.payload;
    })
    .addCase(setActiveHash, (state, action) => {
      state[`${action.payload.type}activeHashes`] = action.payload.hash;
    })
    .addCase(removeActiveHash, (state, action) => {
      state[`${action.payload}activeHashes`] = undefined;
    })
    .addCase(toggleAddTokenModal, (state, action) => {
      state.tokenAAGmodalVisible = action.payload;
    }),
);
