import { VestingContractType } from 'src/hooks/useContract';
import { AppState } from '..';

export const isAuthorizationModalOpen = (state: AppState) => state.app.authorizationModalOpen;

export const isOnboardingModalOpen = (state: AppState) => state.app.onboardingModalOpen;

export const isClaimRewardsModalOpen = (state: AppState) => state.app.claimRewardsModalOpen;

export const getAlertMessage = (state: AppState) => state.app.alertMessage;

export const isAuthorized = (state: AppState) => !!state.app.isAuthorized;

export const getAuthMethod = (state: AppState) => state.app.isAuthorized;

export const getDataRefreshed = (state: AppState) => state.app.refreshedAt;

export const getGlobalMessages = (state: AppState) => state.app.messages;

export const getActiveHash = (state: AppState, pool: VestingContractType) => {
  return state.app[`${pool}activeHashes`];
};

export const isAddTokenAAGModalVisible = (state: AppState) => state.app.tokenAAGmodalVisible;
