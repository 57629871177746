import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'src/state';
import { getSelectedVestingInfo } from 'src/state/account/selectors';
import ProgressBar from '../../../components/ProgressBar';
import moment from 'moment';
import TooltipC from 'src/components/TooltipC';
import LabelMeanings from 'src/components/LabelMeanings';
import { isAuthorized } from 'src/state/app/selectors';
import { Circle } from 'rc-progress';
import { getAAGPrice } from 'src/state/currencies/selectors';
import { FormattedNumber } from 'react-intl';
import { convertBNtoAmount } from 'src/utils';

enum Statuses {
  inprogress = 'inprogress',
  completed = 'completed',
  canceled = 'canceled',
  scheduled = 'scheduled',
}
const VestingScheduleWidget: React.FC = () => {
  // const [procents, setProcents] = useState();
  const [status, setStatus] = useState<Statuses>();
  const [totalTime, setTotalTime] = useState<string>();
  const { vestingInfo, authorized } = useAppSelector((state) => ({
    vestingInfo: getSelectedVestingInfo(state),
    authorized: isAuthorized(state),
    price: getAAGPrice(state),
  }));

  const procentsAndStatus = useMemo(() => {
    if (!!vestingInfo?._canceledTimestamp) {
      setStatus(Statuses.canceled);
      return 100;
    } else if (
      vestingInfo?._startTimestamp !== undefined &&
      vestingInfo?._endTimestamp !== undefined
    ) {
      const difference =
        vestingInfo._endTimestamp.getTime() - vestingInfo._startTimestamp.getTime();

      if (
        vestingInfo._endTimestamp &&
        vestingInfo._endTimestamp.getTime() - new Date().getTime() <= 0
      ) {
        setStatus(Statuses.completed);
        return 100;
      }

      if (vestingInfo._startTimestamp.getTime() > new Date().getTime()) {
        setStatus(Statuses.scheduled);
      } else {
        setStatus(Statuses.inprogress);
      }

      let total_difference = Number((difference / 1000).toFixed(0));

      let diff_years = 0;
      if (total_difference > 60 * 60 * 24 * 365) {
        diff_years =
          vestingInfo._endTimestamp.getFullYear() - vestingInfo._startTimestamp.getFullYear();
        total_difference = total_difference - diff_years * 60 * 60 * 24 * 365;
      }

      let diff_days = 0;
      if (total_difference > 60 * 60 * 24) {
        diff_days = Number((total_difference / (60 * 60 * 24)).toFixed(0));
        total_difference = total_difference - diff_days * 60 * 60 * 24;
      }

      let diff_hours = 0;
      if (total_difference > 60 * 60) {
        diff_hours = Number((total_difference / (60 * 60)).toFixed(0));
        total_difference = total_difference - diff_days * 60 * 60;
      }
      let diff_minutes = 0;
      if (total_difference > 60) {
        diff_minutes = Number((total_difference / (60 * 60)).toFixed(0));
        total_difference = total_difference - diff_days * 60;
      }

      let time = '';
      if (diff_years > 0) {
        time += `${diff_years} years `;
      }
      if (diff_days > 0) {
        time += `${diff_days} days `;
      }
      if (diff_hours > 0) {
        time += `${diff_hours} hours `;
      }
      if (diff_minutes > 0) {
        time += `${diff_minutes} minutes `;
      }
      setTotalTime(time);

      const nowDifference = new Date().getTime() - vestingInfo._startTimestamp.getTime();
      const proc = Number((nowDifference / difference) * 100);
      if (proc < 0) return 0;
      return proc > 100 ? 100 : proc;
    } else {
      setTotalTime(undefined);
      setStatus(undefined);
      return 0;
    }
  }, [vestingInfo]);

  return (
    <div className={`vesting-schedule-widget ${!authorized && 'nonauthorized-mobile'}`}>
      <div className="top">
        <div className="label">
          <span>Vesting Token Schedule</span>
          <TooltipC tooltip={<LabelMeanings />} width={500} />
        </div>
        {authorized && (
          <>
            <>
              {status === Statuses.scheduled && (
                <div className="status planned">
                  <span>Scheduled</span>
                </div>
              )}
            </>
            <>
              {status === Statuses.inprogress && (
                <div className="status active">
                  <span>Vesting</span>
                </div>
              )}
            </>
            <>
              {status === Statuses.completed && (
                <div className="status completed">
                  <span>Completed</span>
                </div>
              )}
            </>
            <>
              {status === Statuses.canceled && (
                <div className="status canceled">
                  <span>Canceled</span>
                </div>
              )}
            </>
            <>
              {status === undefined && (
                <div className="status inactive">
                  <span>Not scheduled</span>
                </div>
              )}
            </>
          </>
        )}
      </div>
      <div className="circle-mobile">
        <Circle
          percent={procentsAndStatus}
          trailWidth={8}
          strokeWidth={8}
          trailColor="rgba(96, 78, 255, 0.2)"
          strokeColor="#604EFF"
        />
        <div>
          <FormattedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            value={procentsAndStatus}
          />
          %
        </div>
      </div>
      <div className="middle">
        <div className="middle-left">
          <span>Vested</span>
          <b>
            <FormattedNumber
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={convertBNtoAmount(vestingInfo._amount)}
            />
          </b>
          <span>
            ~
            <FormattedNumber
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={(convertBNtoAmount(vestingInfo._amount) * (100 - procentsAndStatus)) / 100}
            />
            <span style={{ opacity: 1, marginLeft: 5 }}>AAG left</span>
          </span>
        </div>
        <div className="middle-right">
          <div className="item">
            <div className="label">Vesting period</div>
            <div className="status">{totalTime ? totalTime : '-'}</div>
          </div>
          <div className="item">
            <div className="label">Start date</div>
            <div className="status">
              {!!vestingInfo?._startTimestamp
                ? moment(vestingInfo?._startTimestamp).format('MMM DD YYYY')
                : '-'}
            </div>
          </div>
          <div className="item">
            <div className="label">End date</div>
            <div className="status">
              {!!vestingInfo?._endTimestamp
                ? moment(vestingInfo?._endTimestamp).format('MMM DD YYYY')
                : '-'}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <ProgressBar procentage={Number(procentsAndStatus.toFixed(1))} />
      </div>
    </div>
  );
};

export default VestingScheduleWidget;
