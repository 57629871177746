import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useAppDispatch } from 'src/state';
import { toggleOnboardingModal } from 'src/state/app/actions';
import { ReactComponent as InfoIcon } from '../../assets/images/icons/info.svg';
import { useCookies } from 'react-cookie';
import Welcome from '../../assets/images/onboarding-images/welcome.gif';
import Vesting from '../../assets/images/onboarding-images/vesting.gif';
import Tooltip from '../../assets/images/onboarding-images/tooltip.gif';
import Claim from '../../assets/images/onboarding-images/claim.gif';

const messages = defineMessages({
  skip: {
    id: 'GlobalTranslation.skip',
    defaultMessage: 'Skip',
  },
  next: {
    id: 'GlobalTranslation.next',
    defaultMessage: 'Next',
  },
  title1: {
    id: 'OnboardingModal.title1',
    defaultMessage: 'Welcome to AAG Wallet',
  },
  body1: {
    id: 'OnboardingModal.body1',
    defaultMessage:
      'We prepared your AAG Wallet to see your total AAG allocation, locked and unlocked AAG balances.',
  },
});

const questions = [
  {
    title: 'Welcome to AAG Vesting',
    body: 'We prepared AAG Vesting to see your total AAG allocation, locked and unlocked AAG balances.',
    img: Welcome,
  },
  {
    title: 'Intuitive vesting schedule',
    body: 'You can easily understand how much AAG Tokens have already been vested.',
    img: Vesting,
  },
  {
    title: 'More info if needed',
    body: (
      <div>
        If you need more information about each section of your wallet, just press the
        <InfoIcon style={{ margin: '0 5px' }} />
        icon near the section name.
      </div>
    ),
    img: Tooltip,
  },
  {
    title: 'Claim available tokens',
    body: 'See your available-to-withdraw token amount and claim it easily to your crypto wallet.',
    img: Claim,
  },
];

const OnboardingModal: React.FC = () => {
  const [count, setCount] = useState(0);
  const [cookies, setCookie] = useCookies(['wasUserConnected']);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  return (
    <div id="OnboardingModal" className={!cookies.wasUserConnected ? 'visible' : ''}>
      <div className="modal">
        <div className="header">
          <button
            className="skip-btn"
            onClick={() => {
              dispatch(toggleOnboardingModal(false));
              setCookie('wasUserConnected', 'true', { path: '/' });
            }}
          >
            {intl.formatMessage(messages.skip)}
          </button>
        </div>
        <img style={{ borderRadius: 4 }} src={questions[count].img} alt="onboarding-image" />
        <div className="indicator-wrapper">
          {questions.map((el, i) => (
            <div key={i} className={count === i ? 'indicator active' : 'indicator'}></div>
          ))}
        </div>
        <div className="content">
          <span className="title">{questions[count].title}</span>
          <span className="body">{questions[count].body}</span>
        </div>
        <div className="onboarding-buttons">
          <button
            disabled={count === 0}
            onClick={() => setCount((prevState) => prevState - 1)}
            className="back-btn"
          >
            <span>Back</span>
          </button>
          {count === 3 ? (
            <button
              onClick={() => {
                dispatch(toggleOnboardingModal(false));
                setCookie('wasUserConnected', 'true', { path: '/' });
              }}
              className="next-btn"
            >
              <span>Finish</span>
            </button>
          ) : (
            <button onClick={() => setCount((prevState) => prevState + 1)} className="next-btn">
              <span>{intl.formatMessage(messages.next)}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
