import { createReducer } from '@reduxjs/toolkit';
import { updateAAGPrice } from './actions';
import { CurrenciesState } from './types';

export const accountInitialState: CurrenciesState = {
  AAGPrice: 0,
  aagPriceChange24h: 0,
};

export default createReducer(accountInitialState, (builder) =>
  builder
    // .addCase(setAuthorized, (state, action) => {
    //   if (!action.payload) {
    //     Object.keys(state).forEach((key) => {
    //       // @ts-ignore
    //       state[key] = accountInitialState[key];
    //     });
    //   }
    // })
    .addCase(updateAAGPrice, (state, action) => {
      state.AAGPrice = action.payload.value;
      state.aagPriceChange24h = action.payload.priceChange;
    }),
);
