import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/state';
import { toggleAuthorizationModal } from 'src/state/app/actions';
import { isAuthorizationModalOpen } from 'src/state/app/selectors';
import { Close } from '@material-ui/icons';
import { useIntl, defineMessages } from 'react-intl';
import { isMobile, isChrome } from 'react-device-detect';
import ChromeContent from './content/ChromeContent';
import OtherBrowserContent from './content/OtherBrowserContent';
import MobileContent from './content/MobileContent';

const messages = defineMessages({
  header: {
    id: 'AuthorizationModal.header',
    defaultMessage: 'Choose your wallet',
  },
});

const AuthorizationModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { isModalVisible } = useAppSelector((state) => ({
    isModalVisible: isAuthorizationModalOpen(state),
  }));

  // @ts-ignore
  const isInjected = window.ethereum;

  const renderContent = () => {
    if (isMobile) {
      return <MobileContent />;
    } else if (!isInjected && !isMobile && !isChrome) return <OtherBrowserContent />;
    return <ChromeContent />;
  };

  return (
    <div id="AuthorizationModal" className={`${isModalVisible ? 'visible' : ''} modal`}>
      <div className="modal" style={{ maxWidth: '408px', padding: '24px 30px' }}>
        <div className="header">
          <span style={{ fontSize: '22px' }}>{intl.formatMessage(messages.header)}</span>
          <button
            onClick={() => {
              dispatch(toggleAuthorizationModal(false));
            }}
          >
            <Close className="close-btn" />
          </button>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default AuthorizationModal;
