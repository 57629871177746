import { v4 as uuidv4 } from 'uuid';
import { SupportedChainId } from 'src/constants/chains';
import mainet from '../envs/mainnet/env.json';
import harmony from '../envs/harmonyMainnet/env.json';
export const getConvertedLocale = (locale: string): string => {
  switch (locale) {
    default:
      return 'en';
  }
};

export const uuid = (): string => {
  return uuidv4();
};

export const getConfigBasedOnChainId = (chainId: number) => {
  if (chainId === SupportedChainId.HARMONY) {
    return harmony;
  } else {
    return mainet;
  }
};
