import axios from 'axios';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import useSelectedPool from 'src/hooks/useSelectedPool';
import { updateAAGPrice } from 'src/state/currencies/actions';

const TokenPriceDataHandler: React.FC = () => {
  const dispatch = useDispatch();

  const selectedPool = useSelectedPool();

  const fetchData = async () => {
    if (fetch.isLoading) return;
    try {
      const { data } = await axios.get(
        'https://token-api.aag.ventures/api/market/market-data/?currency=aag-ventures',
      );
      if (data) {
        dispatch(
          updateAAGPrice({
            value: data?.current_price.usd,
            priceChange: data.price_change_percentage_24h,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetch = useMutation(fetchData, {});

  useEffect(() => {
    fetch.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPool]);
  return <></>;
};

export default TokenPriceDataHandler;
