import React from 'react';
import { Close } from '@material-ui/icons';
import { useAppSelector, useAppDispatch } from 'src/state';
import { isClaimRewardsModalOpen } from 'src/state/app/selectors';
import { setAlertMessage, toggleClaimRewardsModal } from 'src/state/app/actions';
import PrimaryButton from '../common/PrimaryButton';
import SecondaryButton from '../common/SecondaryButton';
import claimSuccess from '../../assets/images/claim/claimSuccess.png';

const ClaimRewardsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isModalVisible } = useAppSelector((state) => ({
    isModalVisible: isClaimRewardsModalOpen(state),
  }));

  return (
    <div id="ClaimRewardsModal" className={`modal ${isModalVisible ? 'visible' : ''}`}>
      <div className="modal-wrapper">
        <div className="header">
          <span>You have successfully claimed your AAG tokens!</span>
          <button
            onClick={() => {
              dispatch(toggleClaimRewardsModal(false));
            }}
          >
            <Close className="close-btn" />
          </button>
        </div>
        <div>
          <div className="content-wrapper">
            <img src={claimSuccess} alt="" />
          </div>
          <div className="info-bar-wrapper">
            <span>
              You can now continue earning AAG Tokens and get rewarded with our unique staking
              program.
            </span>
          </div>
          <div className="bw-2">
            <PrimaryButton title={'Go to staking'} />
            <SecondaryButton
              onClick={() => {
                dispatch(toggleClaimRewardsModal(false));
                dispatch(
                  setAlertMessage({
                    type: 'success',
                    text: 'Successfully claimed',
                    bgColor: '#2FD786',
                  }),
                );
              }}
              label={'Quit'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimRewardsModal;
