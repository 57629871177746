import React from 'react';
import { ReactComponent as AagLogo } from '../assets/images/aag-logo-small.svg';
import { useAppSelector } from 'src/state';
import { getAAGPrice } from 'src/state/currencies/selectors';

const TokenPrice = () => {
  const { price } = useAppSelector((state) => ({
    price: getAAGPrice(state),
  }));

  return (
    <div>
      <a
        href="https://coinmarketcap.com/currencies/aag-ventures/"
        target="_blank"
        className="token-price"
        rel="noreferrer"
      >
        <AagLogo style={{ marginRight: '10px', width: '25px', maxHeight: '25px' }} />
        <span>AAG token price</span>
        <span>{`$${price.toFixed(3)}`}</span>
      </a>
    </div>
  );
};

export default TokenPrice;
