import { createAction } from '@reduxjs/toolkit';
import { VestingContractType } from 'src/hooks/useContract';
import { AuthorizationMethods, GlobalMessage } from 'src/types';
import { AlertMessage } from './types';

export const toggleAuthorizationModal = createAction<boolean>('app/toggleAuthorizationModal');

export const toggleOnboardingModal = createAction<boolean>('app/toggleOnboardingModal');

export const toggleClaimRewardsModal = createAction<boolean>('app/toggleClaimRewardsModal');

export const setAlertMessage = createAction<AlertMessage | undefined>('app/setAlert');

export const setAuthorized = createAction<AuthorizationMethods | undefined>('app/setAuthorized');

export const refreshData = createAction<Date>('app/refreshData');

export const setGlobalMessage = createAction<GlobalMessage>('app/setGlobalMessage');

export const removeGlobalMessage = createAction<string>('app/removeGlobalMessage');

// prettier-ignore
export const setActiveHash = createAction<{ type: VestingContractType; hash: string }>('account/setActiveHash');

export const removeActiveHash = createAction<VestingContractType>('account/removeActiveHash');

export const toggleAddTokenModal = createAction<boolean>('app/toggleAddTokenModal');
