import React from 'react';
import { useDispatch } from 'react-redux';
import { MainVestingType } from 'src/hooks/useContract';
import useSelectedPool from 'src/hooks/useSelectedPool';
import { useAppSelector } from 'src/state';
import { setSelectedPool } from 'src/state/account/actions';
import { getAvailablePools } from 'src/state/account/selectors';

const AvailablePools: React.FC = () => {
  const dispatch = useDispatch();
  const availablePools = useAppSelector(getAvailablePools);
  const selectedPool = useSelectedPool();

  return (
    <>
      {availablePools.length > 1 && (
        <div
          id="AvailablePools"
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 0 10px 0',
          }}
        >
          <span
            style={{
              padding: '4px 10px 4px 0',
              margin: '2px 0px',
            }}
          >
            Available pools:{' '}
          </span>
          {availablePools.map((i) => (
            <button
              key={i}
              style={{
                marginRight: 8,
              }}
              className={selectedPool === i ? 'active small-button' : 'small-button'}
              onClick={() => dispatch(setSelectedPool(i as MainVestingType))}
            >
              {i === 'team' ? 'advisors & team' : i}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default AvailablePools;
