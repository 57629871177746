import React from 'react';

const ProgressBar: React.FC<{ procentage: number }> = ({ procentage }) => {
  return (
    <div className="progress-bar-custom">
      <div
        className="ins"
        style={{
          width: `${procentage}%`,
        }}
      ></div>
      {!!procentage ? (
        <div
          className="text"
          style={{
            marginLeft: `${procentage}%`,
          }}
        >{`${procentage}%`}</div>
      ) : (
        <div className="text">0%</div>
      )}
    </div>
  );
};

export default ProgressBar;
