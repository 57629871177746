import en from './en.json';

interface TranslationsInterface {
  [key: string]: { [key: string]: string };
}

const Translations: TranslationsInterface = {
  en,
};

export default Translations;
