import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useAppDispatch } from 'src/state';
import { toggleAuthorizationModal } from 'src/state/app/actions';
import Web3AddressWidget from './Web3AddressWidget';
import useActiveWeb3 from 'src/hooks/useActiveWeb3';
import { useWeb3React } from '@web3-react/core';

const messages = defineMessages({
  header: {
    id: 'MenuLinks.header',
    defaultMessage: 'Connect wallet',
  },
});

const Web3ConnectionButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { account } = useWeb3React();

  if (account === undefined) {
    return (
      <button
        id="connect-wallet"
        className="primary-button"
        onClick={() => {
          dispatch(toggleAuthorizationModal(true));
        }}
      >
        {intl.formatMessage(messages.header)}
      </button>
    );
  }
  return <Web3AddressWidget />;
};

export default Web3ConnectionButton;
