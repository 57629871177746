import { CHAINS, SupportedChainId } from 'src/constants/chains';
import { setGlobalMessage } from 'src/state/app/actions';
import moment from 'moment';
import { GlobalMessage } from 'src/types';
import store from 'src/state';
import { getConfigBasedOnChainId, uuid } from '.';
import { ExternalProvider } from '@ethersproject/providers';

export const addAAGTokenToMetamask = async (
  chainId: SupportedChainId,
  provider?: ExternalProvider,
) => {
  const dispatch = store.dispatch;

  if (CHAINS[chainId]) {
    try {
      if (provider) {
        // @ts-ignore
        window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: getConfigBasedOnChainId(chainId).TOKEN_CONTRACT_ADDRESS,
              symbol: 'AAG',
              decimals: 0,
              image: 'https://saakuru.com/logo512.png',
            },
          },
        });
      }
    } catch (e: any) {
      const message: GlobalMessage = {
        message: e?.message || 'Add token to wallet failed',
        uuid: uuid(),
        expiresAt: moment().add(10, 'seconds').toDate(),
        isError: true,
      };
      dispatch(setGlobalMessage(message));
    }
  }
};
