import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MetamaskButton from '../connectionButtons/MetamaskButton';
// import WalletConnectButton from '../connectionButtons/WalletConnectButton';
import MetaOneButton from '../connectionButtons/MetaOneButton';
import DontHaveCryptoWallet from './DontHaveCryptoWallet';
import WalletConnectButtonV2 from '../connectionButtons/WalletConnectButtonV2';

const messages = defineMessages({
  dontHaveWallet: {
    id: 'AuthorizationModal.chrome.dontHaveWallet',
    defaultMessage: 'Don’t have a crypto wallet?',
  },
});

const ChromeContent: React.FC = () => {
  const intl = useIntl();
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  return (
    <>
      <div className="body">
        <div className="nav-wrapper">
          <MetaOneButton />
          <MetamaskButton setInfoOpen={setInfoOpen} />
          <WalletConnectButtonV2 />
          {/* <WalletConnectButton /> */}
        </div>
      </div>
      <div className="info-button" onClick={() => setInfoOpen(!infoOpen)}>
        {intl.formatMessage(messages.dontHaveWallet)}
      </div>
      {infoOpen && (
        <div style={{ marginTop: 10, marginBottom: '10px' }}>
          <DontHaveCryptoWallet />
        </div>
      )}
    </>
  );
};

export default ChromeContent;
