import React, { useEffect, useState } from 'react';
import { SUPPORTED_WALLETS } from 'src/constants/wallet';
import { useAppDispatch, useAppSelector } from 'src/state';
import { setAuthorized, setGlobalMessage, toggleAuthorizationModal } from 'src/state/app/actions';
import { Spinner } from 'react-bootstrap';
import { AuthorizationMethods, GlobalMessage } from 'src/types';
import { uuid } from 'src/helpers';
import moment from 'moment';
import { Subject } from 'rxjs';
import { MetaMask } from '@web3-react/metamask';
import { metaMask } from 'src/connectors/metaMask';
import { getAuthMethod } from 'src/state/app/selectors';
import { useWeb3React } from '@web3-react/core';
interface Props {
  setInfoOpen?: (state: boolean) => void;
}

export const ReinitializeMetamask = new Subject<() => any>();
const MetamaskButton: React.FC<Props> = ({ setInfoOpen }) => {
  const { authorized } = useAppSelector((state) => ({
    authorized: getAuthMethod(state),
  }));
  const { connector, isActive } = useWeb3React();
  const dispatch = useAppDispatch();
  const [connecting, setConnecting] = useState<boolean>();
  const isInjected = window.ethereum;
  // @ts-ignore
  const isMetaOne = window?.ethereum?.isMetaOne;
  useEffect(() => {
    if (authorized === 'metamask') {
      void metaMask.connectEagerly();
    }
  }, [authorized]);

  const initAuth = () => {
    if (connecting) return;
    if (isInjected) {
      setConnecting(true);
      connector instanceof MetaMask &&
        void connector
          .activate()
          .then(() => {
            dispatch(setAuthorized(AuthorizationMethods.metamask));
            setConnecting(false);
            dispatch(toggleAuthorizationModal(false));
          })
          .catch((error) => {
            let msg = error?.message || 'Something went wrong';
            if (error?.message.includes('Unsupported')) {
              msg =
                'Your MetaMask is set to incorrect network. Please change it to Harmony mainnet or Ethereum mainnet before logging in';
            }
            if (error?.message.includes('window.ethereum')) {
              setInfoOpen && setInfoOpen(true);
              msg = 'No MetaMask wallet found.';
            }
            const message: GlobalMessage = {
              message: msg,
              uuid: uuid(),
              expiresAt: moment().add(20, 'seconds').toDate(),
              isError: true,
            };
            dispatch(setAuthorized(undefined));
            setConnecting(false);
            dispatch(toggleAuthorizationModal(false));
            dispatch(setGlobalMessage(message));
          });
      if (isActive) {
        dispatch(setAuthorized(AuthorizationMethods.metamask));
        setConnecting(false);
        dispatch(toggleAuthorizationModal(false));
      }
    } else {
      // TODO handle explanation how to download metamask
    }
  };

  useEffect(() => {
    ReinitializeMetamask.subscribe((retry: () => unknown) => {
      initAuth();
      setTimeout(() => {
        retry();
      }, 4000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMetaOne) return null;
  return (
    <>
      {isInjected && (
        <div>
          <button
            className="wallet-button"
            onClick={() => {
              initAuth();
            }}
          >
            <span style={{ fontSize: '16px' }}>MetaMask</span>{' '}
            {connecting ? (
              <Spinner animation={'border'} style={{ width: 32, height: 32, marginLeft: 14 }} />
            ) : (
              <img
                src={SUPPORTED_WALLETS['METAMASK'].iconURL}
                style={{ width: 26, height: 26, borderRadius: '100%', background: 'white' }}
              ></img>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default MetamaskButton;
