import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import { defineMessages, useIntl } from 'react-intl';

interface Props {
  header: string;
  text: string;
  dataToCopy: string;
}

const messages = defineMessages({
  copyAddress: {
    id: 'AuthorizationModal.mobile.copyAddress',
    defaultMessage: 'Copy',
  },
  copiedToClipboard: {
    id: 'AuthorizationModal.mobile.copiedToClipboard',
    defaultMessage: 'Copied to clipboard',
  },
});

const ClickToCopyButton: React.FC<Props> = ({ header, text, dataToCopy }) => {
  const [copied, setCopy] = useState<boolean>(false);
  const intl = useIntl();

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(dataToCopy);
    setCopy(true);
  }, [dataToCopy]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopy(false), 3000);
    }
  }, [copied]);

  return (
    <div
      className="copy-network-button"
      style={{ cursor: 'pointer' }}
      onClick={() => handleCopyToClipboard()}
    >
      {copied ? (
        <>
          <span>
            <AiOutlineCheck /> {intl.formatMessage(messages.copiedToClipboard)}
          </span>
        </>
      ) : (
        <>
          <div className="copy-network-button-header">
            <div className="button-header text-muted">{header}</div>
            <div className="button-text">{text}</div>
          </div>
          <div className="d-flex align-items-center" style={{ paddingLeft: 10 }}>
            <FiCopy style={{ marginRight: 10 }} className="purple" />
            <span>{intl.formatMessage(messages.copyAddress)}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ClickToCopyButton;
