import { Spinner } from 'react-bootstrap';
interface Props {
  networkProps: {
    networkID: number;
    id: number;
    networkIcon: string;
    networkName: string;
  };
  initAuth: (x: number) => void;
  setDesiredChainId: (x: number) => void;
  desiredChainId?: number;
}
const WCButton = (props: Props) => {
  const { networkProps, initAuth, setDesiredChainId, desiredChainId } = props;
  const { networkID, id, networkIcon, networkName } = networkProps;
  return (
    <div
      key={id}
      className="wc2-button"
      onClick={() => {
        initAuth(networkID);
        setDesiredChainId(networkID);
      }}
    >
      {desiredChainId === networkID ? (
        <Spinner animation={'border'} style={{ width: 22, height: 22, marginRight: 8 }} />
      ) : (
        <img src={networkIcon} style={{ width: 22, height: 22, marginRight: '8px' }} />
      )}
      {networkName}
    </div>
  );
};

export default WCButton;
