import React from 'react';
import { ReactComponent as Info } from '../../assets/images/icons/info.svg';

const InfoIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <div className="info-icon-wrapper">
      <div className={`${color} info-icon`}>
        <Info />
      </div>
    </div>
  );
};

export default InfoIcon;
