import React from 'react';
import { ReactComponent as Symbol } from '../../assets/images/icons/symbol.svg';
import { ReactComponent as Telegram } from '../../assets/images/icons/Telegram.svg';
import Web3ConnectionButton from './Web3ConnectionButton';
import { defineMessages, useIntl } from 'react-intl';
import BurgerMenu from './BurgerMenu';

const messages = defineMessages({
  home: {
    id: 'Header.home',
    defaultMessage: 'Home',
  },
  staking: {
    id: 'Header.staking',
    defaultMessage: 'Staking',
  },
  withdrawals: {
    id: 'Header.withdrawals',
    defaultMessage: 'Withdrawals',
  },
  telegramChannel: {
    id: 'Header.telegramChannel',
    defaultMessage: 'Telegram',
  },
});

const HeaderComponent: React.FC = () => {
  const intl = useIntl();

  return (
    <div id="Header">
      <div className="header-container d-flex justify-content-between">
        <a
          className="desktop-logo-link"
          href="https://saakuru.com"
          rel="noreferrer"
          target="_blank"
          style={{
            display: 'block',
            width: '160px',
          }}
        >
          <img src={require('../../assets/images/saakuru-logo.svg').default} alt="logo" />
        </a>
        <a className="mobile-logo-link" href="https://saakuru.com" rel="noreferrer" target="_blank">
          <Symbol style={{ width: 40, height: 40 }} />
        </a>
        <div className="w3-button-mobile">
          <Web3ConnectionButton />
        </div>
        <div className="header-menu-wrapper">
          <a className="menu-link" href="https://saakuru.com" rel="noreferrer" target="_blank">
            {intl.formatMessage(messages.home)}
          </a>
          <div className="tel">
            <a
              className="menu-link"
              href="https://www.sushi.com/swap?token0=0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a&token1=0xAE0609A062a4eAED49dE28C5f6A193261E0150eA&chainId=1666600000"
              target="_blank"
              rel="noreferrer"
            >
              Swap
            </a>
          </div>
          <div className="tel">
            <a
              className="menu-link"
              href="https://bridge.harmony.one/erc20"
              target="_blank"
              rel="noreferrer"
            >
              Bridge
            </a>
          </div>
          <div className="tel">
            <a
              className="menu-link"
              href="https://staking.aag.ventures"
              target="_blank"
              rel="noreferrer"
            >
              Staking
            </a>
          </div>
          {/* <div className="tel">
            <a
              className="menu-link"
              href="https://intercom.help/aag-ventures/en"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
          </div> */}

          <div className="tel">
            <Telegram style={{ marginRight: '10px', opacity: '0.6' }} />
            <a
              className="menu-link"
              href="https://t.me/aagventures"
              target="_blank"
              rel="noreferrer"
            >
              Live Chat
            </a>
          </div>
          <div className="w3-button-desktop">
            <Web3ConnectionButton />
          </div>
          <BurgerMenu />
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
