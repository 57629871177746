import { SupportedChainId } from 'src/constants/chains';
import Ethereum from '../assets/images/eth.png';
import Hrmy from '../assets/images/harmony.png';

export const switchList = [
  {
    id: 1,
    networkName: 'Harmony',
    networkID: SupportedChainId.HARMONY,
    networkIcon: Hrmy,
  },
  {
    id: 2,
    networkName: 'Ethereum',
    networkID: SupportedChainId.MAINNET,
    networkIcon: Ethereum,
  },
];
