import React from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title?: string;
  iconright?: React.ReactElement;
  submitting?: boolean;
  buttonType?: string;
}
const PrimaryButton: React.FC<Props> = (props) => {
  const propsButton: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > = {};

  Object.keys(props).forEach(function (key) {
    if (key !== 'title' && key !== 'submitting' && key !== 'button_type') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      propsButton[key] = props[key];
    }
  });
  return (
    <button
      className={`primary-button ${props.buttonType ? props.buttonType : ''}`}
      type="submit"
      disabled={props.submitting}
      {...propsButton}
    >
      <>
        {props.title}
        {props.iconright}
      </>
    </button>
  );
};

export default PrimaryButton;
