import React from 'react';
import { useAppSelector } from 'src/state';
import { isAuthorized } from 'src/state/app/selectors';
import Web3ConnectionButton from './header/Web3ConnectionButton';

interface Props {
  usd?: JSX.Element;
}

const WidgetBottom = (props: Props) => {
  const { authorized } = useAppSelector((state) => ({
    authorized: isAuthorized(state),
  }));
  return authorized ? (
    <div style={{ opacity: 0.6, fontSize: '14px' }}>{props.usd}</div>
  ) : (
    <div style={{ marginTop: '25px' }}>
      <Web3ConnectionButton />
    </div>
  );
};

export default WidgetBottom;
