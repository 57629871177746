import { Row, Col } from 'react-bootstrap';
import VestingScheduleWidget from './widgets/VestingScheduleWidget';
import ReusableWidget from './widgets/ReusableWidget';
import { useAppDispatch, useAppSelector } from 'src/state';
import {
  getAvailableAmountForAddressTotal,
  getSelectedVestingInfoAdditional,
  getTotalAllocation,
  getSelectedTotalLocked,
  getSelectedTotalClaimed,
} from 'src/state/account/selectors';
import { isAuthorized, getAlertMessage } from 'src/state/app/selectors';

import OnboardingModal from 'src/components/modals/OnboardingModal';
import TokenPrice from 'src/components/TokenPrice';
import { setAlertMessage } from 'src/state/app/actions';
import ClaimRewardsModal from 'src/components/modals/ClaimRewardsModal';
import AlertMessage from 'src/components/common/AlertMessage';
import VestingDataHandler from 'src/components/dataHandlers/VestingDataHandler';
import { getAAGPrice } from 'src/state/currencies/selectors';
import WidgetBottom from 'src/components/WidgetBottom';
import { FormattedNumber } from 'react-intl';
import { useCookies } from 'react-cookie';
import { convertBNtoAmount } from 'src/utils';
import ClaimBlock from './widgets/ClaimBlock';
import AvailablePools from './widgets/AvailablePools';

const MainView = () => {
  const dispatch = useAppDispatch();
  const [cookies] = useCookies();
  const {
    availableAmountForAddress,
    authorized,
    alertMessage,
    price,
    lockedBalance,
    totalClaimed,
  } = useAppSelector((state) => ({
    authorized: isAuthorized(state),
    lockedBalance: getSelectedTotalLocked(state),
    totalClaimed: getSelectedTotalClaimed(state),
    vestingAdditionlInfo: getSelectedVestingInfoAdditional(state),
    availableAmountForAddress: getAvailableAmountForAddressTotal(state),
    alertMessage: getAlertMessage(state),
    price: getAAGPrice(state),
  }));

  const { allocation } = useAppSelector((state) => ({
    allocation: getTotalAllocation(state),
  }));

  return (
    <section id="AuthorizedView" className="centerContent">
      <div className="medium-container">
        <div className="main-top">
          <h1 style={{ fontWeight: 'bold' }}>Vesting</h1>
          <TokenPrice />
        </div>
        <AvailablePools />
        <Row>
          <Col xl={4}>
            <ReusableWidget
              text="Your Total Allocation"
              value={
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={convertBNtoAmount(allocation)}
                />
              }
              bottomEl={
                <div
                  style={{
                    opacity: 0.6,
                    fontSize: '14px',
                  }}
                >
                  <FormattedNumber
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency'}
                    currency="usd"
                    value={convertBNtoAmount(allocation) * price}
                  />
                </div>
              }
              tooltip="Total amount of your token allocation."
              border="1px solid rgba(255, 255, 255, 0.2)"
              height="250px"
              padding="32px 24px"
            />
          </Col>
          <Col xl={8}>
            <VestingScheduleWidget />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ReusableWidget
              text="Locked Balance"
              value={
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={convertBNtoAmount(lockedBalance)}
                />
              }
              bottomEl={
                <WidgetBottom
                  usd={
                    <FormattedNumber
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                      style={'currency'}
                      currency="usd"
                      value={price * convertBNtoAmount(lockedBalance)}
                    />
                  }
                />
              }
              tooltip={
                <div>
                  <div style={{ marginBottom: '10px' }}>
                    Amount of locked tokens that is left for vesting.
                  </div>
                  <div>Basically it is the amount left and still locked.</div>
                </div>
              }
              bgColor="rgba(96, 78, 255, 0.15)"
              padding="32px 24px"
            />
          </Col>
          <Col lg={4}>
            <ReusableWidget
              text="Amount Claimed"
              value={
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={convertBNtoAmount(totalClaimed)}
                />
              }
              bottomEl={
                <WidgetBottom
                  usd={
                    <FormattedNumber
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                      style={'currency'}
                      currency="usd"
                      value={price * convertBNtoAmount(totalClaimed)}
                    />
                  }
                />
              }
              tooltip="Amount that was claimed from vested / unlocked tokens."
              bgColor="rgba(96, 78, 255, 0.15)"
              padding="32px 24px"
            />
          </Col>
          <Col lg={4}>
            <ReusableWidget
              text="Available to Claim"
              value={
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={convertBNtoAmount(availableAmountForAddress)}
                />
              }
              bottomEl={
                <WidgetBottom
                  usd={
                    <FormattedNumber
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                      style={'currency'}
                      currency="usd"
                      value={convertBNtoAmount(availableAmountForAddress) * price}
                    />
                  }
                />
              }
              tooltip="Amount that was not claimed from vested/unlocked tokens and is now available to claim."
              bgColor="rgba(96, 78, 255, 0.15)"
              padding="32px 24px"
            />
          </Col>
        </Row>
        <ClaimBlock />
      </div>
      {!cookies.wasUserConnected && <OnboardingModal />}
      {authorized && <VestingDataHandler />}
      {authorized && <ClaimRewardsModal />}
      {/* Alerts */}
      {alertMessage && (
        <AlertMessage
          type={alertMessage.type}
          text={alertMessage.text}
          bgColor={alertMessage.bgColor}
          onClose={() => dispatch(setAlertMessage(undefined))}
        />
      )}
    </section>
  );
};

export default MainView;
