import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateAccountBalance } from 'src/state/account/actions';
import { convertBNtoAmount } from 'src/utils';
import { useTokenContract } from './useContract';
import useSelectedPool from './useSelectedPool';
import useActiveWeb3 from './useActiveWeb3';
import { useWeb3React } from '@web3-react/core';

export default function useWalletBalance() {
  const dispatch = useDispatch();
  const tokenContract = useTokenContract(true);
  const { account } = useWeb3React();
  const fetchBalance = async () => {
    if (fetch.isLoading) return;
    const balAAG = await tokenContract?.balanceOf(String(account));
    console.log('first');
    if (!!balAAG) {
      const val = convertBNtoAmount(balAAG);
      dispatch(updateAccountBalance({ balance: val }));
    }
  };

  const fetch = useMutation(fetchBalance, {});

  const selectedPool = useSelectedPool();

  useEffect(() => {
    if (account) {
      fetch.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, selectedPool]);
}
