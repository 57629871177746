import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { MainVestingType } from 'src/hooks/useContract';
import { setAuthorized } from '../app/actions';
import {
  updateAccountBalance,
  updateAvailableAmount,
  updateMainVestingInfo,
  updateAdditionalVestingInfo,
  updateAvailableAmountAdditional,
  setSelectedPool,
} from './actions';
import { AccountState } from './types';

export const vestingInfoDefault = {
  _startTimestamp: undefined,
  _endTimestamp: undefined,
  _canceledTimestamp: undefined,
  _amount: BigNumber.from(0),
  _totalDrawn: BigNumber.from(0),
  _lastDrawnAt: undefined,
  _withdrawRate: BigNumber.from(0),
  _remainingBalance: BigNumber.from(0),
};

export const accountInitialState: AccountState = {
  accountAAGbalance: 0,
  selectedPool: MainVestingType.backers,
  vestingInfo: {
    backers: vestingInfoDefault,
    ecosystem: vestingInfoDefault,
    partnerships: vestingInfoDefault,
    team: vestingInfoDefault,
  },
  vestingInfoAdditional: {
    backers10: vestingInfoDefault,
    ecosystem10: vestingInfoDefault,
    partnerships: vestingInfoDefault,
    team: vestingInfoDefault,
  },
  availableAmount: {
    backers: BigNumber.from(0),
    ecosystem: BigNumber.from(0),
    partnerships: BigNumber.from(0),
    team: BigNumber.from(0),
  },
  availableAmountAdditional: {
    backers10: BigNumber.from(0),
    ecosystem10: BigNumber.from(0),
    partnerships: BigNumber.from(0),
    team: BigNumber.from(0),
  },
};

export default createReducer(accountInitialState, (builder) =>
  builder
    .addCase(setAuthorized, (state, action) => {
      if (!action.payload) {
        Object.keys(state).forEach((key) => {
          // @ts-ignore
          state[key] = accountInitialState[key];
        });
      }
    })
    .addCase(updateAccountBalance, (state, action) => {
      state.accountAAGbalance = action.payload.balance;
    })
    .addCase(updateMainVestingInfo, (state, action) => {
      state.vestingInfo[action.payload.type] = action.payload.data;
    })
    .addCase(updateAdditionalVestingInfo, (state, action) => {
      state.vestingInfoAdditional[action.payload.type] = action.payload.data;
    })
    .addCase(updateAvailableAmount, (state, action) => {
      state.availableAmount[action.payload.type] = action.payload.data;
    })
    .addCase(updateAvailableAmountAdditional, (state, action) => {
      state.availableAmountAdditional[action.payload.type] = action.payload.data;
    })
    .addCase(setSelectedPool, (state, action) => {
      state.selectedPool = action.payload;
    }),
);
