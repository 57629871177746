import { Close } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'src/state';
import { toggleAddTokenModal } from 'src/state/app/actions';
import { isAddTokenAAGModalVisible } from 'src/state/app/selectors';
import ClickToCopyButton from '../common/ClickToCopyButton';
const messages = defineMessages({
  header: {
    id: 'AddTokenModal.header',
    defaultMessage: 'Information',
  },
  infoText: {
    id: 'AddTokenModal.infoText',
    defaultMessage: '“Add token” function is not supported by your wallet.',
  },
  infoText1: {
    id: 'AddTokenModal.infoText1',
    defaultMessage: 'Please add tokens manualy:',
  },
  b1h: {
    id: 'AddTokenModal.b1h',
    defaultMessage: 'Token Contract Address',
  },
  b1t: {
    id: 'ChangeNetwork.b1t',
    defaultMessage: '0x5ba19d656b65f1684cfea4af428c23b9f3628f97',
  },
  b2h: {
    id: 'AddTokenModal.b2h',
    defaultMessage: 'Token Symbol',
  },
  b2t: {
    id: 'ChangeNetwork.b2t',
    defaultMessage: 'AAG',
  },
});

const AddTokenModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const handleClose = useCallback(() => {
    dispatch(toggleAddTokenModal(false));
  }, [dispatch]);

  const { modalState } = useAppSelector((state) => ({
    modalState: isAddTokenAAGModalVisible(state),
  }));

  return (
    <div id="InfoModal" className={`modal ${!!modalState ? 'visible' : ''}`}>
      <div className="modal-wrapper">
        <div className="header">
          <span>{intl.formatMessage(messages.header)}</span>
          <button onClick={handleClose}>
            <Close className="close-btn" />
          </button>
        </div>
        <p>{intl.formatMessage(messages.infoText)}</p>
        <p>{intl.formatMessage(messages.infoText1)}</p>

        <ClickToCopyButton
          header={intl.formatMessage(messages.b1h)}
          text={intl.formatMessage(messages.b1t)}
          dataToCopy={intl.formatMessage(messages.b1t)}
        />

        <ClickToCopyButton
          header={intl.formatMessage(messages.b2h)}
          text={intl.formatMessage(messages.b2t)}
          dataToCopy={intl.formatMessage(messages.b2t)}
        />
      </div>
    </div>
  );
};

export default AddTokenModal;
