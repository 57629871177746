import React from 'react';
import { Footer, Header } from 'src/components';
import AuthorizationModal from 'src/components/AuthorizationModal/index';
import TokenPriceDataHandler from 'src/components/dataHandlers/TokenPriceDataHandler';
import { useAppSelector } from 'src/state';
import { isAuthorized } from 'src/state/app/selectors';
import AddTokenModal from './components/modals/AddTokenModal';
import useWalletBalance from './hooks/useWalletBalance';

interface Props {
  children: JSX.Element;
}

const AppCointainer: React.FC<Props> = ({ children }) => {
  const authorized = useAppSelector((state) => isAuthorized(state));
  useWalletBalance();
  return (
    <div id="main-section">
      <div className={`main-background${authorized ? ' is-blured' : ''}`}></div>
      <Header />
      <div className="section-wrapper">{children}</div>
      <Footer />
      <AuthorizationModal />
      <TokenPriceDataHandler />
      <AddTokenModal />
    </div>
  );
};

export default AppCointainer;
