import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { CurrenciesState } from './currencies/types';
import { VestingState } from './vesting/types';
import vesting from './vesting';
import app from './app';
import account from './account';
import currencies from './currencies';

import { AppState as AppInnerState } from './app/types';
import { AccountState } from './account/types';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import expireIn from 'redux-persist-transform-expire-in';

export type AppDispatch = typeof store.dispatch;

export interface AppState {
  app: AppInnerState;
  account: AccountState;
  currencies: CurrenciesState;
  vesting: VestingState;
}

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

const authorizationPersistReducer = createFilter(`app`, [
  'isAuthorized',
  'activeHashes',
  'backersactiveHashes',
  'ecosystemactiveHashes',
  'backers10activeHashes',
  'ecosystem10activeHashes',
  'partnershipsactiveHashes',
  'teamactiveHashes',
]);
const expireInMinutes = 30 * 60 * 1000;
const expirationKey = 'persistencyExpiration';
const persistConfig = {
  key: 'AAG-VENTURE-STORE',
  storage,
  transforms: [authorizationPersistReducer, expireIn(expireInMinutes, expirationKey, undefined)],
  blacklist: ['account', 'currencies', 'vesting'],
};

const reducers = combineReducers({
  app,
  account,
  currencies,
  vesting,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
