import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { setAuthorized } from '../app/actions';
import { updateVestingContractBalance, updateVestingContractBalanceAdditional } from './actions';
import { VestingState } from './types';

export const accountInitialState: VestingState = {
  vestingContractBalance: {
    backers: BigNumber.from(0),
    ecosystem: BigNumber.from(0),
    partnerships: BigNumber.from(0),
  },
  vestingContractBalanceAdditional: {
    backers10: BigNumber.from(0),
    ecosystem10: BigNumber.from(0),
    partnerships: undefined,
  },
};

export default createReducer(accountInitialState, (builder) =>
  builder
    .addCase(setAuthorized, (state, action) => {
      if (!action.payload) {
        Object.keys(state).forEach((key) => {
          // @ts-ignore
          state[key] = accountInitialState[key];
        });
      }
    })
    .addCase(updateVestingContractBalance, (state, action) => {
      state.vestingContractBalance[action.payload.type] = action.payload.data;
    })
    .addCase(updateVestingContractBalanceAdditional, (state, action) => {
      state.vestingContractBalanceAdditional[action.payload.type] = action.payload.data;
    }),
);
