import { BigNumber } from 'ethers';
import { convertBNtoDate } from 'src/utils';

type VestingRawData = [
  BigNumber,
  BigNumber,
  BigNumber,
  BigNumber,
  BigNumber,
  BigNumber,
  BigNumber,
  BigNumber,
] & {
  _startTimestamp: BigNumber;
  _endTimestamp: BigNumber;
  _canceledTimestamp: BigNumber;
  _amount: BigNumber;
  _totalDrawn: BigNumber;
  _lastDrawnAt: BigNumber;
  _withdrawRate: BigNumber;
  _remainingBalance: BigNumber;
};
export const aggregateRawVestingData = (vestingData: VestingRawData) => ({
  _startTimestamp:
    vestingData._startTimestamp.toNumber() !== 0 && vestingData._startTimestamp !== undefined
      ? convertBNtoDate(vestingData._startTimestamp)
      : undefined,
  _endTimestamp:
    vestingData._endTimestamp.toNumber() !== 0 && vestingData._endTimestamp !== undefined
      ? convertBNtoDate(vestingData._endTimestamp)
      : undefined,
  _canceledTimestamp:
    vestingData._canceledTimestamp.toNumber() !== 0 && vestingData._canceledTimestamp !== undefined
      ? convertBNtoDate(vestingData._canceledTimestamp)
      : undefined,
  _lastDrawnAt:
    vestingData._lastDrawnAt.toNumber() !== 0 && vestingData._lastDrawnAt !== undefined
      ? convertBNtoDate(vestingData._lastDrawnAt)
      : undefined,
  _remainingBalance: vestingData._remainingBalance,
  _amount: vestingData._amount,
  _totalDrawn: vestingData._totalDrawn,
  _withdrawRate: vestingData._withdrawRate,
});
