import { BigNumber } from 'ethers';
import { AdditionalVestingArray, MainVestingArray } from 'src/hooks/useContract';
import { vestingInfoDefault } from '.';
import { AppState } from '..';

export const getSelectedVestingInfo = (state: AppState) => {
  const selectedPool = getSelectedPool(state);
  return state.account.vestingInfo[selectedPool] || vestingInfoDefault;
};

export const getSelectedVestingInfoAdditional = (state: AppState) => {
  const selectedPool = getSelectedPoolAdditional(state);
  return state.account.vestingInfoAdditional[selectedPool] || vestingInfoDefault;
};

export const getSelectedAvailableAmount = (state: AppState) => {
  const selectedPool = getSelectedPool(state);
  return state.account.availableAmount[selectedPool];
};

export const getSelectedAvailableAmountAdditional = (state: AppState) => {
  let available = BigNumber.from(0);

  const vestingInfoAdditional = getSelectedVestingInfoAdditional(state);
  if (
    vestingInfoAdditional._endTimestamp !== undefined &&
    vestingInfoAdditional._startTimestamp !== undefined &&
    new Date() > new Date(vestingInfoAdditional._endTimestamp)
  ) {
    available = vestingInfoAdditional._remainingBalance;
  }

  return available;
};

export const getTotalAllocation = (state: AppState) => {
  let allocation = BigNumber.from(0);

  const vestingInfo = getSelectedVestingInfo(state);
  const vestingInfoAdditional = getSelectedVestingInfoAdditional(state);

  allocation = allocation.add(vestingInfo._amount);
  allocation = allocation.add(vestingInfoAdditional._amount);

  // Object.keys(state.account.vestingInfo).forEach((i) => {
  //   const a = state.account.vestingInfo[i];
  //   allocation = allocation.add(a._amount);
  // });

  // Object.keys(state.account.vestingInfoAdditional).forEach((i) => {
  //   const a = state.account.vestingInfoAdditional[i];
  //   allocation = allocation.add(a._amount);
  // });

  return allocation;
};

export const getAvailableAmountForAddressTotal = (state: AppState) => {
  let available = BigNumber.from(0);

  const availableAmount = getSelectedAvailableAmount(state);
  const availableAmountAdditional = getSelectedAvailableAmountAdditional(state);

  available = available.add(availableAmount).add(availableAmountAdditional);

  return available;
};

export const getSelectedTotalClaimed = (state: AppState) => {
  let totalClaimed = BigNumber.from(0);

  const vestingInfo = getSelectedVestingInfo(state);
  const vestingInfoAdditional = getSelectedVestingInfoAdditional(state);

  totalClaimed = totalClaimed.add(vestingInfo._totalDrawn);
  totalClaimed = totalClaimed.add(vestingInfoAdditional._totalDrawn);

  return totalClaimed;
};

export const getSelectedTotalLocked = (state: AppState) => {
  let totalLocked = getTotalAllocation(state);

  const totalAvailable = getAvailableAmountForAddressTotal(state);
  totalLocked = totalLocked.sub(totalAvailable);

  const totalClaimed = getSelectedTotalClaimed(state);
  totalLocked = totalLocked.sub(totalClaimed);

  return totalLocked;
};

export const getAccountAAGBalance = (state: AppState): number => {
  return state.account.accountAAGbalance;
};

export const hasAdditionalSchedule = (state: AppState) =>
  getSelectedVestingInfoAdditional(state)._startTimestamp !== undefined &&
  getSelectedVestingInfoAdditional(state)._amount.gt(BigNumber.from(0));

export const getAvailablePools = (state: AppState) => {
  const availablePools: string[] = [];

  MainVestingArray.forEach((i) => {
    const pool = state.account.vestingInfo[i];
    if (pool._amount.gt(BigNumber.from(0)) && !availablePools.includes(i)) availablePools.push(i);
  });

  AdditionalVestingArray.forEach((a) => {
    const pool = state.account.vestingInfoAdditional[a];
    const i = a.replace('10', '');
    if (pool._amount.gt(BigNumber.from(0)) && !availablePools.includes(i)) availablePools.push(i);
  });

  return availablePools;
};

export const getSelectedPool = (state: AppState) => {
  const availablePools = getAvailablePools(state);
  if (availablePools.includes(String(state.account.selectedPool))) {
    return String(state.account.selectedPool);
  } else {
    return availablePools[0] || String(state.account.selectedPool);
  }
};

export const getSelectedPoolAdditional = (state: AppState) => {
  return getSelectedPool(state) + '10';
};
