import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  getSelectedAvailableAmount,
  getSelectedVestingInfo,
  getSelectedVestingInfoAdditional,
  hasAdditionalSchedule,
} from 'src/state/account/selectors';
import { isAuthorized } from 'src/state/app/selectors';
import { convertBNtoAmount } from 'src/utils';
import ReusableWidget from './ReusableWidget';
import { FormattedNumber } from 'react-intl';
import { useAppSelector } from 'src/state';
import ClaimWidgetMain from './ClaimWidgetMain';
import ClaimWidgetAdditional from './ClaimWidgetAdditional';
import { BigNumber } from 'ethers';
import { getAAGPrice } from 'src/state/currencies/selectors';
import WidgetBottom from 'src/components/WidgetBottom';

const ClaimBlock: React.FC = () => {
  const {
    availableAmountForAddress,
    authorized,
    totalAmountAdditional,
    hasAddSchedule,
    isMainClaimed,
    totalAmountMain,
    price,
  } = useAppSelector((state) => {
    const selectedAdditionalPool = getSelectedVestingInfoAdditional(state);
    const selectedPool = getSelectedVestingInfo(state);
    return {
      authorized: isAuthorized(state),
      availableAmountForAddress: getSelectedAvailableAmount(state),
      totalAmountAdditional: selectedAdditionalPool._amount,
      totalAmountMain: selectedPool._amount,
      hasAddSchedule: hasAdditionalSchedule(state),
      isMainClaimed:
        selectedPool._amount.eq(selectedPool._totalDrawn) &&
        selectedPool._totalDrawn.gt(BigNumber.from(0)),
      price: getAAGPrice(state),
    };
  });

  return (
    <>
      {authorized && (
        <Row>
          <Col lg={4}>
            <ReusableWidget
              type="claim"
              text={``}
              bottomEl={
                <div>
                  <div
                    style={{ opacity: 0.6, maxWidth: 180, margin: 'auto' }}
                  >{`Keep in mind that each time you withdraw gas fee appears.`}</div>
                </div>
              }
              border="0"
              padding="30px 12px 30px 12px"
              wrap="normal"
            />
          </Col>
          <Col lg={hasAddSchedule ? 4 : 8}>
            <ReusableWidget
              type="claim"
              text="Vested tokens"
              value={
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={convertBNtoAmount(
                    isMainClaimed ? totalAmountMain : availableAmountForAddress,
                  )}
                />
              }
              bottomEl={
                <>
                  <WidgetBottom
                    usd={
                      <FormattedNumber
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency'}
                        currency="usd"
                        value={
                          price *
                          convertBNtoAmount(
                            isMainClaimed ? totalAmountMain : availableAmountForAddress,
                          )
                        }
                      />
                    }
                  />
                  <ClaimWidgetMain />
                </>
              }
              border="0"
              padding="24px 12px 20px 12px"
              wrap="normal"
              bgColor="rgba(96, 78, 255, 0.15)"
            />
          </Col>
          {hasAddSchedule && (
            <Col lg={4}>
              <ReusableWidget
                type="claim"
                text="10% allocation"
                value={
                  <FormattedNumber
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    value={convertBNtoAmount(totalAmountAdditional)}
                  />
                }
                bottomEl={
                  <>
                    <WidgetBottom
                      usd={
                        <FormattedNumber
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          style={'currency'}
                          currency="usd"
                          value={price * convertBNtoAmount(totalAmountAdditional)}
                        />
                      }
                    />
                    <ClaimWidgetAdditional />
                  </>
                }
                border="0"
                padding="24px 12px 20px 12px"
                wrap="normal"
                bgColor="rgba(96, 78, 255, 0.15)"
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default ClaimBlock;
